/* eslint-disable react/prop-types */
import { Col, Layout, Row, Skeleton } from 'antd';
import propTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { FooterStyle, LayoutContainer, SmallScreenAuthInfo } from './Style';
import TopMenuCollect from './TopMenuCollect';
import config from '../config/config';

const { Header, Content } = Layout;

const ThemeLayout = (WrappedComponent) => {
  class LayoutComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        collapsed: false,
        hide: true,
      };
      this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
      this.setState({
        collapsed: window.innerWidth <= 1200 && true,
      });
    }

    render() {
      const { collapsed, hide } = this.state;
      const { rtl, topMenu, info } = this.props;
      const onShowHide = () => {
        this.setState({
          hide: !hide,
        });
      };

      return (
        <LayoutContainer className={config.mainTemplate === 'lightColor' ? 'ligth-color' : 'dark-color'}>
          <Layout className="layout">
            <Header
              style={{
                position: 'fixed',
                width: '100%',
                top: 0,
                [!rtl ? 'left' : 'right']: 0,
              }}
            >
              <div className="ninjadash-header-content d-flex">
                <div className="ninjadash-header-content__left">
                  <div className="navbar-brand align-cener-v">
                    <Link className="ninjadash-logo top-menu" to="/dashboard">
                      {info?.collect_config?.company?.company_logo?.url ? (
                        <img
                          style={{ maxHeight: 60 }}
                          src={`${process.env.REACT_APP_API_ENDPOINT}/${info?.collect_config?.company?.company_logo?.url}`}
                          alt=""
                        />
                      ) : (
                        <Skeleton.Input active style={{ height: 72 }} />
                      )}
                    </Link>
                  </div>
                </div>
                <div className="ninjadash-header-content__right d-flex">
                  <div className="ninjadash-navbar-menu d-flex align-center-v">
                    {topMenu && window.innerWidth > 767 ? <TopMenuCollect /> : ''}
                  </div>
                </div>
                <div className="ninjadash-header-content__mobile">
                  <div className="ninjadash-mobile-action">
                    <Link className="btn-auth" onClick={onShowHide} to="#" />
                  </div>
                </div>
              </div>
            </Header>
            <div className="ninjadash-header-more">
              <Row>
                <Col md={0} sm={24} xs={24}>
                  <div className="ninjadash-header-more-inner">
                    <SmallScreenAuthInfo hide={hide}>
                      <div style={{ display: 'flex' }}>
                        <div style={{ width: '90%' }}>
                          <TopMenuCollect />
                        </div>
                      </div>
                    </SmallScreenAuthInfo>
                  </div>
                </Col>
              </Row>
            </div>

            <Layout className="atbd-main-layout">
              <Content>
                <WrappedComponent {...this.props} />
                <FooterStyle className="admin-footer">
                  <Row>
                    <Col md={12} xs={24}>
                      <span className="admin-footer__copyright">
                        © 2023<Link to="#">Greendomus</Link>
                      </span>
                    </Col>
                    <Col md={12} xs={24}>
                      <div className="admin-footer__links">
                        <NavLink to="#">Sobre</NavLink>
                        <NavLink to="#">Time</NavLink>
                        <NavLink to="#">Contato</NavLink>
                      </div>
                    </Col>
                  </Row>
                </FooterStyle>
              </Content>
            </Layout>
          </Layout>
          {window.innerWidth <= 991 ? <span className={collapsed ? 'ninjadash-shade' : 'ninjadash-shade show'} /> : ''}
        </LayoutContainer>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      layoutMode: state.ChangeLayoutMode.mode,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      logo: state.auth.logo,
      info: state.collect.info,
    };
  };

  LayoutComponent.propTypes = {
    layoutMode: propTypes.string,
    rtl: propTypes.bool,
    topMenu: propTypes.bool,
    logo: propTypes.string,
  };

  return connect(mapStateToProps)(LayoutComponent);
};
export default ThemeLayout;
