import api from '../../config/api/api';

export const getPlants = async (data) => {
  let response = {};
  if (data?.ismaster) {
    response = await api.post('/units/getbycontract', { contract_id: data.contract_id });
  } else {
    response = await api.post('/units/getbyuserprofile', {
      contract_id: data.contract_id,
      userprofile_id: data.userprofile_id,
    });
  }
  return { ...response.data };
};

export const getReportScopes = async (data) => {
  const response = await api.post('/report-scopes/getconfig', { contract_id: data.contract_id });
  return response.data;
};

export const getRelat = async (data) => {
  const response = await api.post('/reported-emissions/getrelat', data);
  return response.data;
};
