/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Tabs } from 'antd';
import { useMutation } from 'react-query';
import Flags from 'country-flag-icons/react/3x2';
import { postInstitutionalTexts } from '../../services/institutionalTexts';
import { PageHeader } from '../../components/page-headers/page-headers';
import { CardToolbox, Main, PageHeaderStyle } from '../style';
import { getCookiesEncrypted } from '../../utility/localStorageControl';
import { ActiveContract, Contracts } from '../../config/Storage';
import { handleError } from '../../utility/utility';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Encryptor } from '../../utility/encryptor';

const { TextArea } = Input;

function InstitutionalTexts() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [form] = Form.useForm();
  const [activeContract, setActiveContract] = useState(null);
  const [institutionalTexts, serInstitutionalTexts] = useState([]);

  useEffect(() => {
    const cookies = getCookiesEncrypted(ActiveContract);
    setActiveContract(cookies);
    serInstitutionalTexts(cookies?.institutional_texts);
  }, []);

  function errorMessage(error) {
    return addToast(error, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  function successMessage(error) {
    return addToast(error, {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  const submit = useMutation(postInstitutionalTexts, {
    onError: (error) => {
      if (error) {
        return errorMessage(handleError(error, dispatch, navigate));
      }
    },
    onSuccess: (res) => {
      const cookies = getCookiesEncrypted(Contracts);
      const data = cookies?.company?.contracts?.map((item) => {
        if (item.id === activeContract.id) {
          item.institutional_texts = institutionalTexts;
        }
        return item;
      });

      const contracts = {
        ...cookies,
        company: {
          ...cookies.company,
          contracts: data,
        },
      };
      const active = {
        ...activeContract,
        ...res,
        institutional_texts: institutionalTexts,
      };

      sessionStorage.setItem([Contracts], Encryptor.encrypted(JSON.stringify(contracts)));
      sessionStorage.setItem([ActiveContract], Encryptor.encrypted(JSON.stringify(active)));
      successMessage(`${t('institutionalTexts')} ${t('updatedSuccessfully')}`);
    },
  });

  const handleSubmit = () => {
    const record = {
      contract_id: activeContract?.id,
      data: {
        institutional_texts: institutionalTexts,
      },
    };

    submit.mutate(record);
  };

  const items = institutionalTexts.map((item) => {
    const code = item?.language?.slice(item.language.length - 2);
    const Flag = Flags[code];
    return {
      label: (
        <span>
          <Flag style={{ width: 30, heigth: 30 }} />
          {item?.language}
        </span>
      ),
      key: item?.id,
      children: (
        <TextArea
          onChange={(e) => {
            item.content = e.target.value;
          }}
          autoSize={{ minRows: 12 }}
          defaultValue={item?.content}
        />
      ),
    };
  });

  return (
    <>
      <CardToolbox>
        <PageHeaderStyle>
          <PageHeader ghost title={t(`institutionalTexts`)} />
        </PageHeaderStyle>
      </CardToolbox>
      <Main>
        <Cards headless>
          <Form style={{ width: '100%' }} form={form} onFinish={handleSubmit}>
            <Tabs type="card" items={items} />
            <div className="add-user-bottom text-right" style={{ marginTop: 20 }}>
              <Button htmlType="submit" type="primary">
                {t('submit')}
              </Button>
            </div>
          </Form>
        </Cards>
      </Main>
    </>
  );
}

export default InstitutionalTexts;
