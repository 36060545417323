/* eslint-disable react/prop-types */
import React from 'react';
import { Col, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import apiPublic from '../../config/api/apiPublic';

function InputForm(props) {
  const { t } = useTranslation();
  const {
    label,
    name,
    width = '100%',
    xxl,
    xl,
    md,
    xs,
    span = 12,
    disabled,
    mandatory,
    breakLine = false,
    breakBefore = false,
    visible = true,
    placeholder = `${t('input')} ${label}`,
    onChange,
    request,
    populateFields,
  } = props;
  return (
    <>
      {breakBefore && <Col span={24} />}

      <Col xxl={xxl} xl={xl} md={md} xs={xs} span={visible ? span : 0}>
        <Form.Item
          label={label}
          name={name}
          rules={[
            {
              message: name === 'email' ? t('messageEmail') : t('fieldRequired'),
              type: name === 'email' ? 'email' : 'string',
              required: !!mandatory,
            },
          ]}
          onBlur={(e) => {
            if (request) {
              const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

              const record = {
                ...request.data,
                [name]: e.target.value,
              };
              apiPublic({
                url: request.url,
                method: request.method,
                data: record,
              }).then((res) => {
                if (res?.data?.length) {
                  populateFields(res?.data[0], null, null);
                } else {
                  populateFields({}, null, e.target.value);
                }
                if (regexEmail.test(e.target.value)) {
                  setTimeout(() => {
                    document.getElementById('name').focus();
                  }, 0);
                } else {
                  setTimeout(() => {
                    document.getElementById(name).focus();
                  }, 0);
                }
              });
            }
          }}
        >
          <Input id={name} style={{ width }} placeholder={placeholder} disabled={disabled} onChange={onChange} />
        </Form.Item>
      </Col>
      {breakLine && <Col span={24} />}
    </>
  );
}

export default InputForm;
