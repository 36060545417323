/* eslint-disable react/prop-types */
import React from 'react';
import { Col, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

function TextAreaForm(props) {
  const { t } = useTranslation();

  const {
    label,
    name,
    width = '100%',
    xxl,
    xl,
    md,
    xs,
    span,
    type,
    defaultValue,
    disabled,
    autoSize,
    rows,
    mandatory,
    breakLine = false,
    breakBefore = false,

    visible = true,
    placeholder = `${t('input')} ${label}`,
    onChange,
  } = props;
  return (
    <>
      {breakBefore && <Col span={24} />}

      <Col xxl={xxl} xl={xl} md={md} xs={xs} span={visible ? span : 0}>
        <Form.Item label={label} name={name} rules={[{ message: t('fieldRequired'), required: !!mandatory }]}>
          <Input.TextArea
            style={{ width }}
            placeholder={placeholder}
            type={type}
            autoSize={autoSize}
            rows={rows}
            defaultValue={defaultValue}
            disabled={disabled}
            id={name}
            onChange={onChange}
          />
        </Form.Item>
      </Col>
      {breakLine && <Col span={24} />}
    </>
  );
}

export default TextAreaForm;
