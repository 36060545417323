import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputsPageheaderStyle } from './style';
import InputsSteps from './steps';
import InputsTabs from './tabs';
import { InputsProvider } from './context';
import { PageHeader } from '../../components/page-headers/page-headers';
import { CardToolbox, Main } from '../style';

function Inputs() {
  const { t } = useTranslation();

  return (
    <InputsProvider>
      <CardToolbox>
        <InputsPageheaderStyle>
          <PageHeader ghost title={t('expenditureReports')} />
        </InputsPageheaderStyle>
      </CardToolbox>
      <Main>
        <InputsSteps />
        <InputsTabs />
      </Main>
    </InputsProvider>
  );
}

export default Inputs;
