/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState } from 'react';
import { Row, Col, Table, Form, Popconfirm } from 'antd';
import { UilTrashAlt, UilEdit, UilDownloadAlt } from '@iconscout/react-unicons';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { Action, InputTableStyleWrapper } from '../style';
import { useInputsContext } from '../context';
import ModalDocuments from '../modal/modalDocuments';
import { deleteDocument, downloadUpload } from '../../../services/inputs';
import { TableWrapper } from '../../style';
import { Button } from '../../../components/buttons/buttons';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { handleError } from '../../../utility/utility';

function InputsDocuments() {
  const { errorMessage, successMessage, loadingTable, listRelats, listBatchs, dataDocuments, refetchTableDocuments } =
    useInputsContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [dataEdit, setDataEdit] = useState({});
  const [checkedRelats, setCheckedRelats] = useState([]);

  const [checkedBatchs, setCheckedBatchs] = useState([]);
  const [removedFiles, setRemovedFiles] = useState([]);
  const [fileList, setFileList] = useState([]);

  const removeDocument = useMutation(deleteDocument, {
    onError: (error) => {
      if (error) {
        return errorMessage(handleError(error, dispatch, navigate));
      }
    },
    onSuccess: (res) => {
      if (res?.id) {
        onCancel();
        refetchTableDocuments();
        successMessage(`${t('document')} ${t('deletedSuccessfully')}`);
      } else {
        return errorMessage(handleError(res, dispatch, navigate));
      }
    },
  });

  const showModal = (data) => {
    setVisible(true);
    if (data && data?.id) {
      form.resetFields();
      setCheckedRelats([...data.relats]);
      setDataEdit(data);
      setFileList(data?.files);
      form.setFieldsValue({
        ...data,
      });
    } else {
      setCheckedRelats([]);
      form.setFieldsValue({});
      setDataEdit({});
    }
  };

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
    setDataEdit({});
    setCheckedRelats([]);
    setCheckedBatchs([]);
    setFileList([]);
    setRemovedFiles([]);
  };

  const handleDownload = async (attachFileId) => {
    const result = await downloadUpload(attachFileId);
    return result;
  };

  const onHandleDelete = (id) => {
    removeDocument.mutate({
      id,
    });
  };

  const documentsTableData = [];
  if (Array.isArray(dataDocuments)) {
    dataDocuments.map((item) => {
      const { id, files, relats, batchs, updatedBy, updatedDate } = item;
      const names = files?.map((value) => value.name);
      const relat = relats?.map((value) => value).toString();
      const batch = batchs?.map((value) => value).toString();

      return documentsTableData.push({
        key: id,
        id,
        files: (
          <>
            {names?.map((value) => (
              <div className="user-info">
                <span className="user-designation">{value} </span>
              </div>
            ))}
            <div className="user-info">
              <span className="user-designation">{updatedBy}</span>
              <span className="user-designation">{updatedDate}</span>
            </div>
          </>
        ),
        relats: relat,
        batchs: batch,
        download: (
          <Action className="table-actions">
            <Button
              title="download"
              onClick={() => handleDownload(item.files)}
              className="btn-icon"
              type="primary"
              to="#"
              shape="circle"
            >
              <UilDownloadAlt />
            </Button>
          </Action>
        ),
        edit: (
          <Action className="table-actions">
            <Button
              title={t('edit')}
              onClick={() => showModal(item)}
              className="btn-icon"
              type="primary"
              to="#"
              shape="circle"
            >
              <UilEdit color="orange" />
            </Button>
          </Action>
        ),
        delete: (
          <Action className="table-actions">
            <Popconfirm
              title={t('confirmDeleteDocument')}
              onConfirm={() => onHandleDelete(id)}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <Button title={t('delete')} className="btn-icon" type="primary" to="#" shape="circle">
                <UilTrashAlt color="red" />
              </Button>
            </Popconfirm>
          </Action>
        ),
      });
    });
  }
  const documentsTableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('files'),
      dataIndex: 'files',
      key: 'files',
    },
    {
      title: `${t('relats')} ${t('associated')}`,
      dataIndex: 'relats',
      key: 'relats',
      align: 'center',
    },
    listBatchs?.length
      ? {
          title: `${t('batchs')} ${t('associated')}`,
          dataIndex: 'batchs',
          key: 'batchs',
        }
      : {},
    {
      title: <UilDownloadAlt color="#b7b7b7" size={20} />,
      dataIndex: 'download',
      key: 'download',
      width: '20px',
      align: 'center',
    },
    {
      title: <UilEdit color="orange" size={20} />,
      dataIndex: 'edit',
      key: 'edit',
      width: '20px',
      align: 'center',
    },
    {
      title: <UilTrashAlt color="red" size={20} />,
      dataIndex: 'delete',
      key: 'delete',
      width: '20px',
      align: 'center',
    },
  ];
  return (
    <>
      <Row justify="end" style={{ marginBottom: 20 }}>
        <Button
          onClick={showModal}
          className="btn-add_new"
          size="default"
          type="primary"
          key="1"
          style={{ display: listRelats.length > 0 ? '' : 'none' }}
        >
          <Link to="#">+ {`${t('send')} ${t('new')} ${t('document')}`}</Link>
        </Button>
      </Row>
      <Row gutter={15} style={{ display: listRelats.length > 0 ? '' : 'none' }}>
        <Col xs={24}>
          <Cards headless>
            <InputTableStyleWrapper>
              <div className="inputs-table">
                <TableWrapper className="table-responsive">
                  <Table
                    dataSource={documentsTableData}
                    // expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
                    columns={documentsTableColumns}
                    pagination={{
                      defaultPageSize: process.env.REACT_APP_DEFAULTPAGESIZE,
                      total: documentsTableData.length,
                      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    }}
                    loading={loadingTable}
                  />
                </TableWrapper>
              </div>
            </InputTableStyleWrapper>
          </Cards>
        </Col>
      </Row>
      <ModalDocuments
        listRelats={listRelats}
        listBatchs={listBatchs}
        visible={visible}
        form={form}
        setFileList={setFileList}
        fileList={fileList}
        setRemovedFiles={setRemovedFiles}
        removedFiles={removedFiles}
        checkedRelats={checkedRelats}
        checkedBatchs={checkedBatchs}
        setCheckedBatchs={setCheckedBatchs}
        setCheckedRelats={setCheckedRelats}
        dataEdit={dataEdit}
        onCancel={onCancel}
        refetch={refetchTableDocuments}
        tabDocument
      />
    </>
  );
}

export default InputsDocuments;
