/* eslint-disable react/prop-types */
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';
import { useEffect, useState } from 'react';
import { useInputsContext } from '../../pages/inputs/context';

function Aero(props) {
  const { t } = useTranslation();
  const { airports, setAirports } = useInputsContext();

  const {
    label,
    name,
    width = '100%',
    xxl,
    xl,
    md,
    xs,
    span = 12,
    breakLine = false,
    breakBefore = false,
    data,
    value,
    display,
    disabled,
    visible = true,
    placeholder = `${t('select')} ${label}`,
  } = props;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(data);
  }, []);
  return (
    <>
      {breakBefore && <Col span={24} />}
      <Col xxl={xxl} xl={xl} md={md} xs={xs} span={visible ? span : 0}>
        <Form.List name={name}>
          {() => (
            <>
              {airports.map((field, index) => (
                <Form.Item
                  label={index === 0 ? label : ''}
                  required={false}
                  key={field.key}
                  className="dynamic-form-field"
                >
                  <Form.Item {...field} validateStatus={field.error} style={{ width: '100%', padding: 0, margin: 0 }}>
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{
                        width,
                      }}
                      placeholder={placeholder}
                      onFocus={() => {
                        const prevIndex = index - 1;
                        const nextIndex = index + 1;

                        const newOptions = data.filter(
                          (item) => item.id !== airports[prevIndex]?.id && item.id !== airports[nextIndex]?.id,
                        );
                        setOptions([...newOptions]);
                      }}
                      onSelect={(e) => {
                        const newAirports = airports.map((item) => {
                          if (item.key === field.key) {
                            item.id = e;
                            item.error = '';
                          }
                          return item;
                        });
                        setAirports([...newAirports]);
                      }}
                      disabled={disabled}
                      className="select-small"
                      value={field.id}
                      allowClear
                      onClear={() => {
                        const newAirports = airports.map((item) => {
                          if (item.key === field.key) {
                            item.id = null;
                          }
                          return item;
                        });
                        setAirports([...newAirports]);
                      }}
                    >
                      {options.length
                        ? options?.map((item) => (
                            <Select.Option key={item[value]} value={item[value]}>
                              {item[display]}
                            </Select.Option>
                          ))
                        : data?.map((item) => (
                            <Select.Option key={item[value]} value={item[value]}>
                              {item[display]}
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                  {airports.length > 2 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => {
                        const fields = airports.filter((item) => item.key !== field.key);
                        const newAirports = fields.map((item, i) => {
                          const prevIndex = i - 1;
                          if (item.id === fields[prevIndex]?.id) {
                            item.id = null;
                          }
                          return item;
                        });
                        setAirports([...newAirports]);
                      }}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    const newAirports = airports;
                    newAirports.push({
                      key: CryptoJS.lib.WordArray.random(16),
                      id: null,
                    });
                    setAirports([...newAirports]);
                  }}
                  style={{
                    width: '100%',
                  }}
                  icon={<PlusOutlined />}
                />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Col>

      {breakLine && <Col span={24} />}
    </>
  );
}
export default Aero;
