import api from '../../config/api/api';

export const getAllPlants = async (id) => {
  const response = await api.post('/units/getbycontract', { contract_id: id });
  return { ...response.data };
};

export const getColabs = async (data) => {
  const response = await api.post('/user-profiles/getbycontract', data);
  return response.data;
};

export const postPlant = async (data) => {
  const response = await api.post('/units/createunit', data);
  return { ...response.data };
};

export const putPlant = async (data) => {
  const response = await api.post('/units/updateunit', data);
  return { ...response.data };
};

export const deletePlant = async (data) => {
  const response = await api.post('/units/deleteunit', data);
  return { ...response.data };
};

export const getSectors = async () => {
  const response = await api.post('/company-sectors/getcompanysectors');
  return response.data;
};

export const getCertificates = async (id) => {
  const response = await api.post('/contracts/countusedcertificates', { contract_id: id });
  return response.data;
};
