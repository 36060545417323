/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-eval */
/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState } from 'react';
import { Row, Col, Table, Form, Popconfirm, Modal } from 'antd';
import { UilTrashAlt, UilEdit, UilDownloadAlt, UilCommentAltNotes, UilUpload, UilCopy } from '@iconscout/react-unicons';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import CryptoJS from 'crypto-js';
import InputsForm from './form';
import { useInputsContext } from '../context';
import ModalDocuments from '../modal/modalDocuments';
import { Action, Copied, CopyLink, InputTableStyleWrapper, LineInfo } from '../style';
import { TableWrapper, AddModal } from '../../style';
import {
  deleteInput,
  postInput,
  putInput,
  downloadUpload,
  getXlsxEmissions,
  getXlsxForms,
} from '../../../services/inputs';
import { Button } from '../../../components/buttons/buttons';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { handleError } from '../../../utility/utility';

function InputsTable() {
  const {
    state,
    errorMessage,
    successMessage,
    columnsForm,
    dataTable,
    fields,
    setFields,
    refetchTable,
    activeContract,
    contracts,
    loadingTable,
    valuesNumber,
    setValuesNumber,
    errorsNumbers,
    setErrorsNumbers,
    setAffected,
    collect,
    keyunit,
    affected,
    warningMessage,
    airports,
    setAirports,
    initialAirports,
    hwResult,
  } = useInputsContext();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formUpload] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [dataEdit, setDataEdit] = useState({});
  const [visibleUpload, setVisibleUpload] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [removedFiles, setRemovedFiles] = useState([]);
  const [dataEditUpload, setDataEditUpload] = useState({});
  const [titleUpload, setTitleUpload] = useState('');
  const [copied, setCopied] = useState(false);

  const showModal = (data) => {
    form.resetFields();
    if (data?.id) {
      setDataEdit(data);

      const optionsDependents = [];

      const elements = fields.map((el) => {
        if (el.dependents) {
          const elOptions = el?.options?.filter((value) => {
            const elName = data[el.name]?.id ?? data[el.name];
            return value.id == elName;
          });
          optionsDependents.push({ name: el.dependents, options: elOptions[0]?.[el.dependents] ?? el.options });
        }

        return el;
      });
      const namesNumbers = [];
      let items = {};

      const newFields = elements.map((el) => {
        if (el.required) {
          el.mandatory = true;
        } else {
          el.mandatory = false;
        }
        if (el.dependencies) {
          const element = optionsDependents?.find((item) => item.name === el.name);

          el.options = element?.options;
          if (el.required) {
            el.disabled = false;
          }
          if (el.type === 'group') {
            el.visible = false;
            const objController = elements.find((item) => item.name === el.dependencies);
            const groupControlled =
              objController.options.find((item) => item.id === data[el.dependencies].id)?.controlled_group ??
              objController.options.find((item) => item.value === data[el.dependencies])?.controlled_group;

            el.visible = el.name === groupControlled ? true : el.visible;
          }
        }
        if (el?.components) {
          el.components.map((component) => {
            items = handleDataItems(data, component, items);
            return component;
          });
        } else {
          items = handleDataItems(data, el, items);
        }

        if (el.type.includes('decimal') || el.type.includes('int')) {
          namesNumbers.push(el.name);
        }
        return el;
      });
      setFields([...newFields]);
      const values = { ...data, ...items };

      let valuesNumbers = {};
      Object.keys(values).forEach((item) => {
        if (typeof values[item] === 'number') {
          valuesNumbers = { ...valuesNumbers, [item]: { value: values[item], float: values[item] } };
        }
      });
      setValuesNumber({ ...valuesNumbers });
      form.setFieldsValue(values);
    } else {
      const newFields = fields.map((item) => {
        if (item.dependencies) {
          item.disabled = true;
        } else {
          item.disabled = false;
        }
        if (item.required) {
          item.mandatory = true;
        } else {
          item.mandatory = false;
        }
        return item;
      });
      form.setFieldsValue({});
      setDataEdit({});
      setFields([...newFields]);
    }
    setVisible(true);
  };

  function handleDataItems(data, el, items) {
    const trips = [];
    Object.keys(data).forEach((item) => {
      const splitName = el.name.split('.');
      if (item === splitName[0]) {
        if (typeof data[item] === 'object') {
          items = { ...items, [item]: data[item]?.id };
        }
        if (Array.isArray(data[item])) {
          if (el.name === 'trips') {
            items = { [item]: data[item] };
            data[item]?.map((trip) => {
              trips.push({
                key: CryptoJS.lib.WordArray.random(16),
                id: trip,
              });
              return item;
            });
          } else if (el.type === 'select-multiple') {
            items = { [item]: data[item] };
          } else {
            const values = data[item].reduce((result, value) => {
              const key = Object.keys(value)[0];
              result[`${item}.${key}`] = value[key];
              return result;
            }, {});
            items = { ...items, ...values };
          }
        }
      }
    });
    setAirports([...trips]);
    return items;
  }
  const onCancel = () => {
    let valuesNumbers = {};
    Object.keys(valuesNumber).forEach((item) => {
      if (typeof valuesNumber[item] === 'number') {
        valuesNumbers = { ...valuesNumbers, [item]: { value: '', float: '' } };
      }
    });
    // let newData = {};
    // Object.keys(dataEdit).forEach((item) => {
    //   newData = { ...newData, [item]: '' };
    // });
    setAirports(initialAirports);
    setValuesNumber({ ...valuesNumbers });
    setErrorsNumbers({});
    form.setFieldsValue({});
    form.resetFields();
    setVisible(false);
    setAffected({});
    setDataEdit({});
  };
  const onCancelUpload = () => {
    formUpload.resetFields();
    setVisibleUpload(false);
    setDataEditUpload({});
    setFileList([]);
    setRemovedFiles([]);
  };

  const showModalUpload = (data) => {
    setVisibleUpload(true);
    if (data && data?.id) {
      formUpload.resetFields();
      setDataEditUpload(data);
      setTitleUpload(`${t('to')}: ${data.description}`);
      setFileList(data?.files);
      formUpload.setFieldsValue({
        ...data,
      });
    }
  };
  const removeInput = useMutation(deleteInput, {
    onError: (error) => {
      if (error) {
        return errorMessage(handleError(error, dispatch, navigate));
      }
    },
    onSuccess: () => {
      onCancel();
      refetchTable();
      successMessage(`${t('report')} ${t('deletedSuccessfully')}`);
    },
  });

  const createInput = useMutation(postInput, {
    onError: (error) => {
      if (error) {
        return errorMessage(handleError(error, dispatch, navigate));
      }
    },
    onSuccess: () => {
      onCancel();
      refetchTable();
      successMessage(`${t('report')} ${t('createSuccessfully')}`);
    },
  });

  const editInput = useMutation(putInput, {
    onError: (error) => {
      if (error) {
        return errorMessage(handleError(error, dispatch, navigate));
      }
    },
    onSuccess: () => {
      onCancel();
      refetchTable();
      successMessage(`${t('report')} ${t('updatedSuccessfully')}`);
    },
  });

  function handleErrorNumber() {
    const numbersInput = [].slice.call(document.getElementsByClassName('numbers-input'));
    let numbers = null;
    let errors = errorsNumbers;
    let group = {};
    let total = {};

    numbersInput.map((item) => {
      const value = item.id.split('.');
      const required = !!item.className.includes('fieldRequired');
      if (value[1]) {
        if (Number(item.name)) {
          const valueGroup = group[value[0]] ?? [];
          valueGroup.push({ [value[1]]: Number(item.name) });
          group = { ...group, [value[0]]: valueGroup };
          const valueItem = total[value[0]] ?? 0;
          total = { ...total, [value[0]]: valueItem + Number(item.name) };
        } else if (required) {
          errors = { ...errors, [value[1]]: t('fieldRequired') };
        }
        numbers = { ...numbers, ...group };
        return numbers;
      }
      if (Number(item.name)) {
        total = { ...total, [value[0]]: Number(item.name) };

        numbers = { ...numbers, [value[0]]: Number(item.name) };
      } else if (required) {
        errors = { ...errors, [value[0]]: t('fieldRequired') };
      }
      return numbers;
    });
    let countErrorNumbers = 0;
    Object.keys(errors).forEach((item) => {
      if (errors[item]) {
        countErrorNumbers += 1;
      }
    });
    if (countErrorNumbers) {
      setErrorsNumbers({ ...errors });

      return { verify: false, numbers, total };
    }
    return { verify: true, numbers, total };
  }

  const handleSubmit = async (values) => {
    const { numbers, verify, total } = await handleErrorNumber();
    if (!verify) return;
    let items = { ...values, ...numbers };
    let valuesVerify = {};
    const errorsVerify = [];
    let countAirports = null;
    let errorsAirports = null;

    fields.map((item) => {
      if (item.name === 'trips') {
        countAirports = 0;
        errorsAirports = 0;

        const newAirports = airports.map((airport) => {
          if (airport.id) {
            countAirports += 1;
          } else {
            errorsAirports += 1;
            airport.error = 'error';
          }
          return airport;
        });

        setAirports([...newAirports]);
      }
      if (item.visible && item.verify) {
        Object.keys(items).forEach((key) => {
          if (key === item.verifyName) {
            valuesVerify = { ...valuesVerify, [item.verifyName]: total[item.verifyName] };
          }
        });
        if (item.verify && !eval(item.verify)) {
          errorsVerify.push(item.verifyName);
        }
      }

      return item;
    });
    if (errorsAirports !== null && errorsAirports > 0) {
      return;
    }

    if (countAirports >= 2) {
      const trips = airports.map((trip) => trip.id);

      items = { ...items, trips };
    }
    if (errorsVerify.length) {
      return errorMessage(
        handleError({
          response: {
            status: 301,
            data: { error: { message: `${t('errorInFields')}${errorsVerify.toString()}${t('checkAndTryAgain')}` } },
          },
        }),
      );
    }

    if (dataEdit?.id) {
      const record = {
        keycode: state?.reportType,
        id: dataEdit?.id,
        data: {
          company: contracts?.company?.id,
          contract: activeContract?.id,
          year: activeContract?.year,
          unit: state?.unit,
          sector: contracts?.company?.company_sector?.id,
          updater_user_profile: activeContract?.userprofile_id,
          ...items,
        },
      };

      editInput.mutate(record);
    } else {
      const record = {
        keycode: state?.reportType,
        data: {
          company: contracts?.company?.id,
          contract: activeContract?.id,
          year: activeContract?.year,
          unit: state?.unit,
          sector: contracts?.company?.company_sector?.id,
          updater_user_profile: activeContract?.userprofile_id,
          ...items,
        },
      };

      createInput.mutate(record);
    }
  };

  const handleDownload = async (attachFileId) => {
    const result = await downloadUpload(attachFileId);
    return result;
  };

  const onHandleDelete = (id) => {
    removeInput.mutate({
      keycode: state?.reportType,
      id,
    });
  };

  const downEmissions = async () => {
    getXlsxEmissions(`report_${state?.reportType}_Unit_${state?.unit}`, {
      keycode: state?.reportType,
      unit: state?.unit,
    });
  };

  const downForms = async () => {
    getXlsxForms(`report_${state?.reportType}_Unit_${state?.unit}`, {
      keycode: state?.reportType,
      unit: state?.unit,
    });
  };

  function arraySplit(param, item) {
    let value;
    const array = param?.split('.');
    if (Array.isArray(array)) {
      if (array?.length > 1) {
        value = item[array[0]][array[1]];
      } else {
        value = item[array[0]];
      }
    }

    return value;
  }

  function convertValue(value, item, format) {
    let newValue;
    const valueSplit = value.split(',');
    const arrayValue = [];
    if (Array.isArray(valueSplit)) {
      valueSplit.map((param, i) => {
        const obj = arraySplit(param, item);
        if (format) {
          arrayValue.push(
            <div className="user-info block">
              <span className={`${i > 0 ? 'user-designation' : 'user-name'}`}>
                {new Intl.NumberFormat(contracts.user_language, {
                  minimumFractionDigits: format.decimals,
                  maximumFractionDigits: format.decimals,
                }).format(obj)}
              </span>
            </div>,
          );
        } else {
          arrayValue.push(
            <div className="user-info block">
              <span className={`${i > 0 ? 'user-designation' : 'user-name'}`}>{obj}</span>
            </div>,
          );
        }
        newValue = [...arrayValue];
        return param;
      });
    }

    return newValue;
  }
  const inputsTableData = [];
  if (Array.isArray(dataTable)) {
    dataTable.map((item) => {
      const { id, note, batch } = item;
      let obj = {};

      columnsForm?.table_config?.map((value) => {
        const newValue = convertValue(value?.dataIndex, item, value?.format);
        obj = { ...obj, [value?.dataIndex]: newValue, note, batch };
        return value;
      });
      const filesToDownload = [];
      if (Array.isArray(item?.documents) && item?.documents.length) {
        item?.documents?.forEach((doc) => {
          if (Array.isArray(doc?.doc_file) && doc?.doc_file.length) {
            doc?.doc_file?.forEach((file) => {
              filesToDownload.push({ url: file.url, name: file.name });
            });
          }
        });
      }
      if (batch !== null) {
        if (Array.isArray(batch?.r_documents) && batch?.r_documents.length) {
          batch?.r_documents?.forEach((doc) => {
            if (Array.isArray(doc?.doc_file) && doc?.doc_file.length) {
              doc?.doc_file?.forEach((file) => {
                filesToDownload.push({ url: file.url, name: file.name });
              });
            }
          });
        }
      }
      return inputsTableData.push({
        key: id,
        ...obj,
        note: (
          <Action className="table-actions">
            <Button title={note} className="btn-icon" type="primary" to="#" shape="circle" disabled={collect}>
              <UilCommentAltNotes style={{ color: note ? '#8231D3' : '#b7b7b7' }} />
            </Button>
          </Action>
        ),
        download: (
          <Action className="table-actions">
            <Button
              title={
                Array.isArray(filesToDownload) && !filesToDownload.length
                  ? ''
                  : `${filesToDownload.length} ${t('files')}`
              }
              onClick={
                Array.isArray(filesToDownload) && !filesToDownload.length ? null : () => handleDownload(filesToDownload)
              }
              className="btn-icon"
              type="primary"
              to="#"
              shape="circle"
              style={
                Array.isArray(filesToDownload) && !filesToDownload.length ? { color: '#b7b7b7' } : { color: '#8231D3' }
              }
              disabled={collect}
            >
              <UilDownloadAlt />
            </Button>
          </Action>
        ),
        upload: !batch ? (
          <Action className="table-actions">
            <Button
              title="Upload"
              onClick={() => showModalUpload(item)}
              className="btn-icon"
              type="primary"
              to="#"
              shape="circle"
              disabled={collect}
            >
              <UilUpload />
            </Button>
          </Action>
        ) : null,
        edit: !batch ? (
          <Action className="table-actions">
            <Button
              title={t('edit')}
              onClick={() => showModal(item)}
              className="btn-icon"
              type="primary"
              to="#"
              shape="circle"
              disabled={collect}
            >
              <UilEdit color="orange" />
            </Button>
          </Action>
        ) : null,
        delete: !batch ? (
          <Action className="table-actions">
            <Popconfirm
              title={t('confirmDeleteReport')}
              onConfirm={() => onHandleDelete(id)}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <Button title={t('delete')} className="btn-icon" type="primary" to="#" shape="circle" disabled={collect}>
                <UilTrashAlt color="red" />
              </Button>
            </Popconfirm>
          </Action>
        ) : null,
      });
    });
  }
  const columns = Array.isArray(columnsForm?.columns) ? columnsForm?.columns : [];
  const inputsTableColumns = [
    ...columns,
    {
      title: <UilCommentAltNotes color="#b7b7b7" size={20} />,
      dataIndex: 'note',
      key: 'note',
      width: '20px',
      align: 'center',
    },
    {
      title: <UilDownloadAlt color="#b7b7b7" size={20} />,
      dataIndex: 'download',
      key: 'download',
      width: '20px',
      align: 'center',
    },
    {
      title: <UilUpload color="#b7b7b7" size={20} />,
      dataIndex: 'upload',
      key: 'upload',
      width: '20px',
      align: 'center',
    },
    {
      title: <UilEdit color="orange" size={20} />,
      dataIndex: 'edit',
      key: 'edit',
      width: '20px',
      align: 'center',
    },
    {
      title: <UilTrashAlt color="red" size={20} />,
      dataIndex: 'delete',
      key: 'delete',
      width: '20px',
      align: 'center',
    },
  ];

  function handleHwResult() {
    let result = '';
    console.log(`hwResult = ${JSON.stringify(hwResult)}`);
    switch (hwResult.result) {
      case 'Insuficient':
        result = (
          <div style={{ fontWeight: 'bold', color: 'red' }}>
            {t('collectInsuficient')} {hwResult.required}
          </div>
        );
        break;
      case 'Sufficient':
        result = <div style={{ fontWeight: 'bold', color: 'green' }}>{t('collectSufficient')}</div>;
        break;
      default:
        result = null;
    }
    return result;
  }
  return (
    <>
      <LineInfo style={{ marginBottom: 20 }}>
        {collect && (
          <>
            <Col span={18}>
              {copied ? (
                <Copied>Copiado!</Copied>
              ) : (
                <CopyLink
                  title="Clique para copiar"
                  onClick={() => {
                    const link = `${process.env.REACT_APP_FRONT_URL}/collect/${keyunit}`;
                    navigator.clipboard.writeText(link);
                    setCopied(true);
                    setTimeout(() => {
                      setCopied(false);
                    }, 2000);
                  }}
                >
                  <a
                    className="link-copy"
                    title={`Navegar até: ${process.env.REACT_APP_FRONT_URL}/collect/${keyunit}`}
                    // target="_blank"
                    // href={`${process.env.REACT_APP_FRONT_URL}/collect/${keyunit}`}
                    rel="noreferrer"
                  >
                    {`${process.env.REACT_APP_FRONT_URL}/collect/${keyunit}`}
                    <UilCopy color="#1890ff" className="icon-copy" />
                  </a>
                </CopyLink>
              )}
            </Col>
          </>
        )}
        <Col span={collect ? 6 : 24} className="line-buttons">
          {collect && (
            <Button
              onClick={downForms}
              type="secondary"
              size="default"
              style={{
                marginRight: 15,
                display:
                  activeContract?.ismaster && Array.isArray(inputsTableData) && inputsTableData.length > 0
                    ? ''
                    : 'none',
              }}
            >
              {t('xlsxforms')}
            </Button>
          )}
          <Button
            onClick={downEmissions}
            type="secondary"
            size="default"
            style={{
              marginRight: 15,
              display:
                activeContract?.ismaster && Array.isArray(inputsTableData) && inputsTableData.length > 0 ? '' : 'none',
            }}
          >
            {t('xlsxemissions')}
          </Button>
          <Button
            onClick={showModal}
            className="btn-add_new"
            size="default"
            type="primary"
            key="1"
            style={{ display: columnsForm?.form_config?.label.length > 0 ? '' : 'none' }}
          >
            <Link to="#">
              + {t('add')} {state?.buttonTitle}
            </Link>
          </Button>
        </Col>
      </LineInfo>
      {collect && hwResult?.result && (
        <Row>
          <Col style={{ padding: '0px 0px 10px 10px' }} span={24}>
            {handleHwResult()}
          </Col>
        </Row>
      )}
      <Row gutter={15} style={{ display: inputsTableData.length > 0 ? '' : 'none' }}>
        <Col xs={24}>
          <Cards headless>
            <InputTableStyleWrapper>
              <div className="inputs-table">
                <TableWrapper className="table-responsive">
                  <Table
                    dataSource={inputsTableData}
                    size="small"
                    // expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
                    columns={inputsTableColumns}
                    pagination={{
                      defaultPageSize: process.env.REACT_APP_DEFAULTPAGESIZE,
                      total: inputsTableData.length,
                      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    }}
                    loading={loadingTable}
                  />
                </TableWrapper>
              </div>
            </InputTableStyleWrapper>
          </Cards>
        </Col>
      </Row>
      <Modal
        width="90%"
        type="primary"
        title={columnsForm?.form_config?.label}
        visible={visible}
        footer={null}
        onCancel={onCancel}
        maskClosable={false}
        className="modal-compact"
      >
        <AddModal>
          <div className="project-modal ">
            <Form
              style={{ width: '100%' }}
              form={form}
              onFinish={handleSubmit}
              onFinishFailed={() => {
                handleErrorNumber();
              }}
              size="small"
            >
              <Row>
                <InputsForm
                  form={form}
                  locale={contracts?.user_language}
                  fields={fields}
                  setFields={setFields}
                  errorsNumbers={errorsNumbers}
                  valuesNumber={valuesNumber}
                  setValuesNumber={setValuesNumber}
                  setErrorsNumbers={setErrorsNumbers}
                  affected={affected}
                  setAffected={setAffected}
                  warningMessage={warningMessage}
                />
              </Row>
              <Row justify="end">
                <div className="text-right" style={{ marginRight: 10 }}>
                  <Button type="danger" outlined onClick={() => onCancel()}>
                    {t('cancel')}
                  </Button>
                </div>
                <div className="add-input-bottom text-right">
                  <Button htmlType="submit" type="primary">
                    {t('submit')}
                  </Button>
                </div>
              </Row>
            </Form>
          </div>
        </AddModal>
      </Modal>
      <ModalDocuments
        visible={visibleUpload}
        form={formUpload}
        setFileList={setFileList}
        fileList={fileList}
        setRemovedFiles={setRemovedFiles}
        removedFiles={removedFiles}
        dataEdit={dataEditUpload}
        onCancel={onCancelUpload}
        refetch={refetchTable}
        title={titleUpload}
      />
    </>
  );
}

export default InputsTable;
