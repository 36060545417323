import Styled from 'styled-components';

const StepsContainer = Styled.div`
  width: 100%;
  min-height: 100%;
  padding:10px;
  margin-top:20px;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
`;

const Action = Styled.div`
  .active{
    color: #FA8B0C;
  }
`;

const CopyLink = Styled.div`
padding: 10px;
width:550px;
display: flex;
justify-content: space-between;
height: 43px;
border-radius: 6px;
cursor: pointer;
.icon-copy{
  display: none ;
}
.link-copy{
  flex-wrap:nowrap;
  white-space: nowrap;
 
}
&:hover {
  background-color: #ccffcc;
    .icon-copy{
      display: block ;
    }
  }

`;

const Copied = Styled.div`
padding: 10px;
width:550px;
display: flex;
justify-content: center;
height: 43px;
border-radius: 6px;
background-color: #ccffcc;
cursor: pointer;
font-weight:bold
`;

const LineInfo = Styled.div`
display: flex;  
.line-buttons{
    display: flex !important; 
    justify-content: end;
}
@media only screen and (max-width: 1140px){
  display: block !important;
  .line-buttons{
   max-width:100%
}
}
`;

const InputsPageheaderStyle = Styled.div`
  padding:0px !important;
  margin: 0px !important;
  .ant-page-header-heading-title{
    margin-right: 0;
    padding-right: 0;
    &:after{
      display: none;
    }
  }
  .ant-select .ant-select-selection-search-input{
    border-radius: 6px;
  }
`;
const InputsCardWrapper = Styled.div`
  position: relative;
  button{
    position: absolute;
    background: transparent !important;
    top: 0;
    right: 0;
    padding: 0;
    svg {      
      color: ${({ theme }) => theme[theme.mainContent]['extra-light-text']};
      width: 18px;
      height: 18px;
    }
  }
  .inputs-card{
    figure{
      text-align: center;
      margin: 0;
      img{
        border-radius: 50%;
      }
      figcaption{
        margin-top: 20px;
        h3{
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 0;
        }
        span{
          color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
        }
      }
    }
    .input-info{
      margin-top: 20px;
      border-top: 1px solid ${({ theme }) => theme[theme.mainContent]['border-color-default']};
      padding-top: 25px;
      ul{
        li{
          display: flex;
          align-items: center;
          color: ${({ theme }) => theme[theme.mainContent]['gray-light-text']};
          &:not(:last-child){
            margin-bottom: 14px;
          }
          svg{
            width: 16px;
            height: 16px;
            ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 12px;
            color: ${({ theme }) => theme[theme.mainContent]['gray-light-text']};
          }
        }
      }
    }
  }
`;

const InputTableStyleWrapper = Styled.nav`
  table{
    tbody{
      td{
        .input-info{
          .input-name{
            font-size: 14px;
          }
        }
        span.status-text{
          font-size: 12px;
          padding: 0 12.41px;
          line-height: 1.9;
          font-weight: 500;
          border-radius: 12px;
          text-transform: capitalize;
          display: inline-block !important;
          background: #ddd;
          &.active{
            background-color: ${({ theme }) => theme['success-color']}15;
            color: ${({ theme }) => theme['success-color']};
          }
          &.deactivate{
            background-color: ${({ theme }) => theme['warning-color']}15;
            color: ${({ theme }) => theme['warning-color']};
          }
          &.blocked{
            background-color: ${({ theme }) => theme['danger-color']}15;
            color: ${({ theme }) => theme['danger-color']};
          }
        }
      }
    }
  }
  .ant-table-pagination.ant-pagination{
    width: 100%;
    text-align: ${({ theme }) => (!theme.rtl ? 'right' : 'left')};
    border-top: 1px solid ${({ theme }) => theme[theme.mainContent]['border-color-default']};
    margin-top: 0 !important;
    align-items: center;
    padding-top: 30px;
    @media only screen and (max-width: 767px){
      text-align: center;
    }
  }
  .contact-table{
    table{
      tr{
        th,
        td{
          &:first-child{
            ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 20px;
          }
          &:last-child{
            ${({ theme }) => (theme.rtl ? 'padding-left' : 'padding-right')}: 20px;
          }
        }
      }
      .table-actions{
        button{
          width: 32px;
          height: 32px;
          padding: 0;
          background-color: transparent;
          &:hover{
            background-color: transparent;
          }
          &.ant-btn-primary{
            &:hover{
              color: #ADB4D2;
            }
          }
        }
      }
      
      tbody >tr.ant-table-row-selected >td{
        background-color: ${({ theme }) => theme[theme.mainContent]['primary-background']}10;
      }
    }
  }
`;

export {
  LineInfo,
  Copied,
  CopyLink,
  Action,
  InputsPageheaderStyle,
  InputsCardWrapper,
  InputTableStyleWrapper,
  StepsContainer,
};
