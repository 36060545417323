const actions = {
  INFO: 'INFO',
  CONFIRM: 'CONFIRM',

  setInfo: (data) => {
    return {
      type: actions.INFO,
      data,
    };
  },

  setConfirmation: (data) => {
    return {
      type: actions.CONFIRM,
      data,
    };
  },
};

export default actions;
