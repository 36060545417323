/* eslint-disable import/no-cycle */
import actions from './actions';

const { logoutBegin, logoutSuccess, logoutErr, loginInfo, setRefresh } = actions;

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      sessionStorage.clear();
      dispatch(loginInfo({}));
      dispatch(setRefresh(false));
      dispatch(logoutSuccess(false));
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { logOut };
