/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useLayoutEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { TopMenuStyle } from './Style';

function TopMenuCollect() {
  const { t } = useTranslation();

  useLayoutEffect(() => {
    const active = document.querySelector('.ninjadash-top-menu a.active');
    const activeDefault = () => {
      const megaMenu = active.closest('.megaMenu-wrapper');
      const hasSubMenuLeft = active.closest('.has-subMenu-left');
      if (!megaMenu) {
        active.closest('ul').previousSibling.classList.add('active');
        if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
      } else {
        active.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
      }
    };
    window.addEventListener('load', active && activeDefault);
    return () => window.removeEventListener('load', activeDefault);
  }, []);
  return (
    <TopMenuStyle>
      <div className="ninjadash-top-menu">
        <span>{t('topMenuCollection')}</span>
      </div>
    </TopMenuStyle>
  );
}

export default TopMenuCollect;
