import { combineReducers } from 'redux';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import dataTable from './data-filter/reducers';
import inputs from './inputs/reducers';
import { headerSearchReducer } from './headerSearch/reducers';
import CollectReducer from './collect/reducers';

const rootReducers = combineReducers({
  auth: authReducer,
  ChangeLayoutMode,
  collect: CollectReducer,
  headerSearchData: headerSearchReducer,
  dataTable,
  inputs,
});

export default rootReducers;
