import { ConfigProvider, Spin } from 'antd';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastProvider } from 'react-toast-notifications';
import 'antd/dist/antd.less';
import React, { useEffect, useState, lazy } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { isLoaded, ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import { ThemeProvider } from 'styled-components';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import ProtectedRoute from './components/utilities/protectedRoute';
import config from './config/config';
import store, { rrfProps } from './redux/store';

import Admin from './routes/private';
import Auth from './routes/public';
import CollectRoute from './routes/collect';
import './static/css/style.css';
import 'react-phone-number-input/style.css';
import { getConfigCollect } from './services/collect';
import actionsAuth from './redux/collect/actions';

const NotFound = lazy(() => import('./pages/404'));

const { themeColor } = config;

function ProviderConfig() {
  const { i18n } = useTranslation();
  const [collect, setCollect] = useState(null);
  const dispatch = useDispatch();
  const { setInfo } = actionsAuth;
  const { rtl, isLoggedIn, topMenu, mainContent, loading } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      mainContent: state.ChangeLayoutMode.mode,
      isLoggedIn: state.auth.login,
      loading: state.auth.loading,
    };
  });
  const queryClient = new QueryClient();

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);

  useEffect(() => {
    const defaultLang = Cookies.get('defaultLang');

    i18n.changeLanguage(defaultLang);
    const splitPath = window.location.pathname.split('/');
    if (splitPath[1] === 'collect') {
      setCollect(true);
      getConfigCollect(splitPath[2]).then((res) => {
        dispatch(setInfo(res.data));
      });
    } else {
      setCollect(false);
    }
  }, [window.location.pathname]);

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
        <ToastProvider placement="bottom-center">
          <ThemeProvider theme={{ ...themeColor, rtl, topMenu, mainContent }}>
            <ReactReduxFirebaseProvider {...rrfProps}>
              {!isLoaded(loading) ? (
                <div className="spin">
                  <Spin />
                </div>
              ) : (
                <SimpleReactLightbox>
                  <Router basename={process.env.PUBLIC_URL}>
                    {collect !== false ? (
                      <Routes>
                        <Route path="/collect/*" element={<CollectRoute />} />
                      </Routes>
                    ) : !isLoggedIn ? (
                      <Routes>
                        <Route path="/*" element={<Auth />} />
                      </Routes>
                    ) : (
                      <Routes>
                        <Route path="/*" element={<ProtectedRoute path="/*" Component={Admin} />} />
                        <Route path="*" element={<NotFound />} />
                      </Routes>
                    )}
                    {isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
                      <Routes>
                        <Route path="/" element={<Navigate to="/dashboard" />} />
                      </Routes>
                    )}
                  </Router>
                </SimpleReactLightbox>
              )}
            </ReactReduxFirebaseProvider>
          </ThemeProvider>
        </ToastProvider>
      </ConfigProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default App;
