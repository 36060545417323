import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import withAdminLayout from '../layout/withAdminLayout';
import { getCookiesEncrypted } from '../utility/localStorageControl';
import { ActiveContract } from '../config/Storage';
import Dashboard from '../pages/dashboard';
import Inputs from '../pages/inputs';
import InstitutionalTexts from '../pages/institutionalTexts';
import Report from '../pages/report';

const UserProfile = lazy(() => import('../pages/userProfile'));
const CompanyProfile = lazy(() => import('../pages/companyProfile'));
const Users = lazy(() => import('../pages/users'));
const Plants = lazy(() => import('../pages/plants'));
const NotFound = lazy(() => import('../pages/404'));

const Admin = React.memo(() => {
  const [ismaster, setIsMaster] = useState(null);
  const { pathname } = useLocation();
  useEffect(() => {
    const cookies = getCookiesEncrypted(ActiveContract);
    setIsMaster(cookies?.ismaster);
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route index path="/dashboard" element={<Dashboard />} />
        <Route path="user-profile" element={<UserProfile />} />
        <Route path="report" element={<Report />} />
        <Route path="inputs/*" element={<Inputs />} />

        {ismaster && (
          <>
            <Route path="institutional-texts" element={<InstitutionalTexts />} />
            <Route path="company-profile" element={<CompanyProfile />} />
            <Route path="users" element={<Users />} />
            <Route path="plants" element={<Plants />} />
          </>
        )}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
