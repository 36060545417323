import actions from './actions';
import toData from '../../demoData/inputs-data.json';

const initialState = {
  data: toData,
  loading: false,
  error: null,
};

const { INPUTS_READ_BEGIN, INPUTS_READ_SUCCESS, INPUTS_READ_ERR } = actions;
const InputsReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case INPUTS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case INPUTS_READ_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case INPUTS_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export default InputsReducer;
