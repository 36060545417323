import axios from 'axios';
import api from '../../config/api/apiPublic';

export const getConfigCollect = async (keyunit) => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}reports/collect/${keyunit}`);

  return response;
};

export const postCollect = async (data) => {
  const response = await api.post(`reports/collect-create/`, data);
  return { ...response.data };
};

export const putCollect = async (data) => {
  const response = await api.post(`reports/collect-update/`, data);
  return { ...response.data };
};
