import React from 'react';
import { useTranslation } from 'react-i18next';
import { Result } from 'antd';
import { useLocation } from 'react-router-dom';

function ConfirmationCollect() {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <>
      <Result
        style={{ minHeight: '75vh' }}
        status="success"
        title={`${t('greteful')} ${location.state.name}${t('forHisCollaboration')}.`}
      />
    </>
  );
}

export default ConfirmationCollect;
