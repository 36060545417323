/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';
import { Menu } from 'antd';
import { UilThunderstormSun, UilTruck, UilBuilding, UilFileDownloadAlt } from '@iconscout/react-unicons';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Flags from 'country-flag-icons/react/3x2';
import { DashboardCardWrapper } from './style';
import { replaceKeycode } from '../../utility/utility';
import { Dropdown } from '../../components/dropdown/dropdown';

function DashboardCard({ plants, reportScopes, activeContract, setLoadingDownload }) {
  const navigate = useNavigate();

  const { t } = useTranslation();
  function renderIcon(keycode) {
    switch (keycode) {
      case 'sc001':
        return <UilBuilding style={{ height: 30, width: 30 }} />;
      case 'sc002':
        return <UilThunderstormSun style={{ height: 30, width: 30 }} />;
      case 'sc003':
        return <UilTruck style={{ height: 30, width: 30 }} />;
      case 'sc004':
        return <UilBuilding style={{ height: 30, width: 30 }} />;
      case 'sc005':
        return <UilThunderstormSun style={{ height: 30, width: 30 }} />;
      case 'sc006':
        return <UilTruck style={{ height: 30, width: 30 }} />;
      default:
        return null;
    }
  }

  async function handleRelat() {
    setLoadingDownload(true);
    const theFile = `${process.env.REACT_APP_API_ENDPOINT}/uploads/report_demo-01.pdf`;
    const link = document.createElement('a');
    link.href = theFile;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setLoadingDownload(false);
  }

  function getItem({ label, key, data }) {
    const icon = renderIcon(key);
    const reportType = [];
    const children = data?.map((item) => {
      if (item.title === null) {
        item?.report_type.map((value) => {
          reportType.push({ label: value.title, key: value.keycode });
          return value;
        });
        return;
      }
      return getItem({
        label: item.title,
        key: item.keycode,
        data: item?.report_subcategories,
      });
    });

    let newChildren = null;
    if (children?.indexOf(undefined) === -1) {
      newChildren = children;
    } else if (!children) {
      newChildren = null;
    } else {
      newChildren = reportType;
    }

    return {
      key,
      // icon: icon || '',
      children: newChildren,
      label: !icon ? label : label,
    };
  }
  function renderMenus() {
    const items = reportScopes?.map((item) => {
      if (item?.report_categories?.length) {
        return getItem({ label: item.title, key: item.keycode, data: item.report_categories });
      }
      return null;
    });
    return items;
  }

  const items = activeContract?.report_languages?.map((item) => {
    const code = item?.language?.slice(item.language.length - 2);
    const Flag = Flags[code];
    return (
      <Link onClick={() => handleRelat()} to="#">
        <span>
          <Flag style={{ width: 30, heigth: 30 }} />
          {item?.language}
        </span>
      </Link>
    );
  });

  return (
    <DashboardCardWrapper>
      <div className="dashboard-card">
        <figure>
          <figcaption>
            <h3>{plants?.organization?.name}</h3>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '95%' }}>
                {plants?.organization?.city} - {plants?.organization?.state} {`(${plants?.organization?.country})`}
              </span>
              {activeContract.ismaster && (
                <div className="ninjadash-nav-actions__item ninjadash-nav-actions__language">
                  <Dropdown key={plants.id} placement="bottomRight" content={items} trigger="click">
                    <UilFileDownloadAlt style={{ cursor: 'pointer', color: '#008c00' }} title={t('unitEmissions')} />
                  </Dropdown>
                </div>
              )}
            </div>
          </figcaption>
        </figure>

        <Menu
          mode="horizontal"
          items={renderMenus()}
          style={{ display: 'flex', justifyContent: 'center', margin: 20 }}
          onSelect={({ key }) => {
            navigate(`/inputs/${plants.id}/${replaceKeycode(key)}`);
          }}
        />
      </div>
    </DashboardCardWrapper>
  );
}

export default DashboardCard;
