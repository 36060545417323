import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'br',
  lng: 'br',
  resources: {
    en: {
      translations: require('./localization/en/translation.json'),
    },
    es: {
      translations: require('./localization/es/translation.json'),
    },
    br: {
      translations: require('./localization/br/translation.json'),
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
});

i18n.languages = ['en-US', 'es-CL', 'pt-BR'];

export default i18n;
