import React, { lazy, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AuthLayout from '../pages/auth';

const AccountConfirmation = lazy(() => import('../pages/auth/accountConfirmation'));
const ResetPass = lazy(() => import('../pages/auth/resetPassword'));
const ForgotPass = lazy(() => import('../pages/auth/forgotPassword'));
const Login = lazy(() => import('../pages/auth/signIn'));

const AuthRoot = () => {
  const navigate = useNavigate();

  useEffect(() => navigate('/'));
};

const FrontendRoutes = React.memo(() => {
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path="forgot-password" element={<ForgotPass />} />
      <Route path="reset-password" element={<ResetPass />} />
      <Route path="account-confirmation" element={<AccountConfirmation />} />

      <Route path="*" element={<AuthRoot />} />
    </Routes>
  );
});

export default AuthLayout(FrontendRoutes);
