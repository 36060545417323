/* eslint-disable react/prop-types */
// import { message, Upload } from 'antd';
import { Upload } from 'antd';
import React from 'react';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

function UploadDragger({ fileList, setFileList, removedFiles, setRemovedFiles, accept, readCsvFile, isBatch }) {
  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      const removed = removedFiles;
      removed.push(file.uid);
      setRemovedFiles([...removedFiles]);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      if (isBatch) {
        readCsvFile(file);
      }
      return false;
    },
    fileList,
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  return (
    <>
      <Dragger
        {...props}
        fileList={fileList}
        onChange={onChange}
        showUploadList={{ showPreviewIcon: false }}
        multiple
        accept={accept}
        maxCount={isBatch ? 1 : null}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">+ Upload</p>
      </Dragger>
    </>
  );
}
export default UploadDragger;
