import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Modal } from 'antd';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DashboardPageheaderStyle } from './style';
import DashboardCard from './dashboardCard';
import { getPlants, getReportScopes } from '../../services/dashboard';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main, CardToolbox } from '../style';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { Cards } from '../../components/cards/frame/cards-frame';
import { getCookiesEncrypted } from '../../utility/localStorageControl';
import { ActiveContract } from '../../config/Storage';
import { handleError } from '../../utility/utility';

function Dashboard() {
  const { t } = useTranslation();

  const [activeContract, setActiveContract] = useState(null);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const cookies = getCookiesEncrypted(ActiveContract);
    setActiveContract(cookies);
  }, []);

  function usePlants(data) {
    return useQuery(['units', data], () => {
      if (data?.contract_id) {
        return getPlants(data)
          .then((res) => res?.units)
          .catch((error) => handleError(error, dispatch, navigate));
      }
    });
  }

  const { data: plants, isLoading } = usePlants({
    contract_id: activeContract?.id,
    userprofile_id: activeContract?.userprofile_id,
    ismaster: activeContract?.ismaster,
  });

  function useReportScopes(data) {
    return useQuery(['report_scopes', data], () => {
      if (data?.contract_id) {
        return getReportScopes(data)
          .then((res) => res)
          .catch((error) => handleError(error, dispatch, navigate));
      }
    });
  }

  const { data: reportScopes } = useReportScopes({
    contract_id: activeContract?.id,
  });

  const handleSearch = (e) => {
    setSearch(e);
  };
  return (
    <>
      <Modal open={loadingDownload} title={t('downloadEmissions')} footer={null} closable={false}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spin />
        </div>
      </Modal>
      <CardToolbox>
        <DashboardPageheaderStyle>
          <PageHeader
            ghost
            title={t('dashboard')}
            subTitle={<AutoComplete onSearch={handleSearch} placeholder={t('searchUnit')} width="100%" patterns />}
          />
        </DashboardPageheaderStyle>
      </CardToolbox>

      <Main>
        <Row gutter={25}>
          {isLoading ? (
            <Spin style={{ width: '100%', display: 'flex', justifyContent: 'center' }} />
          ) : (
            Array.isArray(plants) &&
            plants?.map(
              (item) =>
                item?.organization?.name.toUpperCase().includes(search?.toUpperCase()) && (
                  <Col key={item.id} xxl={6} xl={8} sm={12} xs={24}>
                    <Cards headless>
                      <DashboardCard
                        plants={item}
                        reportScopes={reportScopes}
                        activeContract={activeContract}
                        setLoadingDownload={setLoadingDownload}
                      />
                    </Cards>
                  </Col>
                ),
            )
          )}
        </Row>
      </Main>
    </>
  );
}

export default Dashboard;
