/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';
import { getBatchs, getColumnsForm, getDataTable, getDocuments } from '../../services/inputs';
import { getCookiesEncrypted } from '../../utility/localStorageControl';
import { ActiveContract, Contracts } from '../../config/Storage';
import { handleError } from '../../utility/utility';
import { getPlants, getReportScopes } from '../../services/dashboard';

const InputsContext = createContext();

function useInputsContext() {
  return useContext(InputsContext);
}

function InputsProvider({ children }) {
  const { t } = useTranslation();
  const initialAirports = [
    { key: CryptoJS.lib.WordArray.random(16), id: null },
    { key: CryptoJS.lib.WordArray.random(16), id: null },
  ];
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [activeContract, setActiveContract] = useState(null);
  const [contracts, setContracts] = useState(null);
  const [categories, setCategories] = useState({});
  const [subCategories, setSubCategories] = useState({});
  const [reportTypes, setReportTypes] = useState({});
  const [inputTypes, setInputTypes] = useState({});
  const [state, setState] = useState({
    unit: null,
    scope: null,
    category: null,
    subCategory: null,
  });
  const [fields, setFields] = useState([]);
  const [tabsTitles, setTabsTitles] = useState([]);
  const [errorsNumbers, setErrorsNumbers] = useState({});
  const [valuesNumber, setValuesNumber] = useState({});

  const [listRelats, setListRelats] = useState([]);
  const [listBatchs, setListBatchs] = useState([]);
  const [affected, setAffected] = useState({});
  const [collect, setCollect] = useState(false);
  const [keyunit, setKeyunit] = useState('');
  const [airports, setAirports] = useState(initialAirports);
  const [tripsOptions, setTripsOptions] = useState([]);
  const [hwResult, setHwResult] = useState({});
  useEffect(() => {
    const cookies = getCookiesEncrypted(ActiveContract);
    const contract = getCookiesEncrypted(Contracts);
    setContracts(contract);
    setActiveContract(cookies);
  }, []);

  const { data: reportScopes } = useReportScopes({
    contract_id: activeContract?.id,
  });

  const { data: columnsForm, refetch: refetchForm } = useColumnsForm({
    keycode: `${state.reportType}-${state.inputKey}`,
  });

  const {
    data: dataTable = [],
    refetch: refetchTable,
    isLoading: loadingTable,
  } = useDataTable({
    year: activeContract?.year,
    unit: state?.unit,
    keycode: state?.reportType,
  });

  const { data: dataDocuments = [], refetch: refetchTableDocuments } = useDocuments({
    year: activeContract?.year,
    unit: state?.unit,
    keycode: state?.reportType,
    contract: activeContract?.id,
    company: contracts?.company?.id,
  });

  const { data: dataBatchs = [], refetch: refetchTableBatch } = useBatch({
    year: activeContract?.year,
    unit: state?.unit,
    keycode: state?.reportType,
  });

  const { data: plants } = usePlants({
    contract_id: activeContract?.id,
    userprofile_id: activeContract?.userprofile_id,
    ismaster: activeContract?.ismaster,
  });

  useEffect(() => {
    handlePath();
  }, [reportScopes, pathname]);

  function handlePath() {
    const path = pathname.split('/');
    const scope = findKeycode(reportScopes, path[3], () => {});

    const category = findKeycode(scope?.report_categories, `${path[3]}-${path[4]}`, setCategories);
    const subCategory = findKeycode(
      category?.report_subcategories,
      `${path[3]}-${path[4]}-${path[5]}`,
      setSubCategories,
    );
    const reportType = findKeycode(
      subCategory?.report_type,
      `${path[3]}-${path[4]}-${path[5]}-${path[6]}`,
      setReportTypes,
    );
    let keycode;
    if (reportType?.input_types) {
      reportType.input_types.map((type) => {
        const keyType = type.keycode.split('-');
        if (keyType[4] === 'it003') {
          setCollect(true);
        } else {
          setCollect(false);
        }
        return type;
      });
      if (reportType?.input_types.length === 1) {
        setInputTypes(reportType?.input_types);
        setTabsTitles([
          { key: 'reports', label: t('relats') },
          { key: 'documents', label: t('documents') },
        ]);
      }

      if (reportType?.input_types.length === 2) {
        setTabsTitles([
          { key: 'reports', label: t('relats') },
          { key: 'batchs', label: t('batchs') },
          { key: 'documents', label: t('documents') },
        ]);
      }
      if (reportType?.input_types.length === 3) {
        setTabsTitles([{ key: 'reports', label: t('Reports') }]);
      }
      keycode = reportType?.input_types[0]?.keycode?.split('-');
    }
    let sub = subCategory?.keycode;
    if (!category?.report_subcategories[0]?.title) {
      setReportTypes(category?.report_subcategories[0].report_type);
      sub = 'n/a';
    }
    setState({
      unit: Number(path[2]),
      scope: scope?.keycode,
      category: category?.keycode,
      subCategory: sub,
      reportType: reportType?.keycode,
      buttonTitle: reportType?.title,
      reportKey: Array.isArray(keycode) ? keycode[3] : null,
      inputKey: Array.isArray(keycode) ? keycode[4] : null,
    });
  }
  function findKeycode(list, keycode, setList) {
    if (Array.isArray(list)) {
      setList([...list]);
      return list?.find((elm) => elm.keycode === keycode);
    }
  }

  function warningMessage(warning) {
    return addToast(warning, {
      appearance: 'warning',
      autoDismiss: true,
    });
  }

  function errorMessage(error) {
    return addToast(error, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  function successMessage(success) {
    return addToast(success, {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  function useReportScopes(data) {
    return useQuery(['report_scopes', data], () => {
      if (data?.contract_id) {
        return getReportScopes(data)
          .then((res) => res)
          .catch((error) => handleError(error, dispatch, navigate));
      }
    });
  }

  function useColumnsForm(data) {
    return useQuery({
      queryKey: ['columns-form', data],
      queryFn: () => {
        if (state?.reportType && state.inputKey) {
          return getColumnsForm(data)
            .then((res) => {
              const list = res?.table_config?.filter((item) => item.dataIndex !== 'batch' && item.dataIndex !== 'note');

              const form =
                res?.form_config?.fields?.map((item) => {
                  if (item.type.includes('decimal')) {
                    item.decimal = item.type.match(/\((.*)\)/).pop();
                  }

                  if (item.verify) {
                    const name = item.verify.split(/[^A-Za-z]/);
                    item.verifyName = name[0];
                    item.verify = `valuesVerify.${item.verify}`;
                  }
                  if (item.name === 'trips') {
                    setTripsOptions(item.options);
                  }
                  return item;
                }) ?? [];
              setFields([...form]);
              return { ...res, columns: list };
            })
            .catch((error) => handleError(error, dispatch, navigate));
        }
      },
      refetchOnWindowFocus: false,
    });
  }
  useEffect(() => {
    handleKeyunit(plants);
  }, [state.unit]);

  function handleKeyunit(units) {
    units?.map((unit) => {
      if (unit.id === state.unit) {
        setKeyunit(unit.keycode);
        setHwResult(unit.hw_result);
      }
      return unit;
    });
  }
  function usePlants(data) {
    return useQuery(['units', data], () => {
      if (data?.contract_id) {
        return getPlants(data)
          .then((res) => {
            handleKeyunit(res.units);

            return res.units;
          })
          .catch((error) => handleError(error, dispatch, navigate));
      }
    });
  }

  function useDataTable(data) {
    return useQuery(['data-table', data], () => {
      if (data?.year && data?.unit && data?.keycode) {
        return getDataTable(data, data?.keycode)
          .then((res) => {
            const relats = [];
            res.map((item) => {
              relats.push({ value: item.id, label: item.id, checked: false });
              return item;
            });
            const batchs = res.filter((item) => item.batch !== null && item.batch !== 0);

            setListBatchs([...batchs]);
            setListRelats(relats);
            return res;
          })
          .catch((error) => {
            handleError(error, dispatch, navigate);
          });
      }
    });
  }

  function useDocuments(data) {
    return useQuery(['data-documents', data], () => {
      if (data?.year && data?.unit && data?.keycode && data?.company && data?.contract) {
        return getDocuments(data)
          .then((res) => res)
          .catch((error) => {
            handleError(error, dispatch, navigate);
          });
      }
    });
  }

  function useBatch(data) {
    return useQuery(['data-batch', data], () => {
      if (data?.year && data?.unit && data?.keycode) {
        return getBatchs(data)
          .then((res) => res)
          .catch((error) => {
            handleError(error, dispatch, navigate);
          });
      }
    });
  }

  const InputsContextValue = {
    activeContract,
    setActiveContract,
    contracts,
    setContracts,
    categories,
    setCategories,
    subCategories,
    setSubCategories,
    reportTypes,
    setReportTypes,
    inputTypes,
    setInputTypes,
    state,
    setState,
    fields,
    setFields,
    errorMessage,
    successMessage,
    warningMessage,
    dataTable,
    refetchTable,
    loadingTable,
    columnsForm,
    reportScopes,
    tabsTitles,
    setTabsTitles,
    dataDocuments,
    refetchTableDocuments,
    listRelats,
    listBatchs,
    errorsNumbers,
    setErrorsNumbers,
    valuesNumber,
    setValuesNumber,
    affected,
    setAffected,
    refetchForm,
    collect,
    keyunit,
    setKeyunit,
    airports,
    setAirports,
    initialAirports,
    tripsOptions,
    dataBatchs,
    refetchTableBatch,
    plants,
    hwResult,
  };
  return <InputsContext.Provider value={InputsContextValue}>{children}</InputsContext.Provider>;
}

export { useInputsContext, InputsProvider };
