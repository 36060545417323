/* eslint-disable react/prop-types */
import React from 'react';
import { Col, Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';

function SelectForm(props) {
  const { t } = useTranslation();
  const {
    label,
    name,
    width = '100%',
    xxl,
    xl,
    md,
    xs,
    span = 12,
    breakLine = false,
    breakBefore = false,
    data,
    value,
    display,
    onChange,
    mandatory,
    disabled,
    visible = true,
    placeholder = `${t('select')} ${label}`,
  } = props;
  return (
    <>
      {breakBefore && <Col span={24} />}

      <Col xxl={xxl} xl={xl} md={md} xs={xs} span={visible ? span : 0}>
        <Form.Item label={label} name={name} rules={[{ message: t('fieldRequired'), required: !!mandatory }]}>
          <Select
            showSearch
            filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
            style={{
              width,
            }}
            placeholder={placeholder}
            onSelect={(e) => {
              const option = data.find((el) => el.id === e);
              onChange(option);
            }}
            disabled={disabled}
            className="select-small"
          >
            {Array.isArray(data) &&
              data?.map((item) => (
                <Select.Option key={item[value]} value={item[value]}>
                  {item[display]}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      {breakLine && <Col span={24} />}
    </>
  );
}

export default SelectForm;
