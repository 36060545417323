import CryptoJS from 'crypto-js';

export class Encryptor {
  static encrypted(value) {
    const encrypted = CryptoJS.AES.encrypt(value, process.env.REACT_APP_SECRET_KEY);
    return encrypted;
  }

  static decrypted(value) {
    const decrypted = CryptoJS.AES.decrypt(value, process.env.REACT_APP_SECRET_KEY);
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
