import React from 'react';
import { Row, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import InputsTable from './relats/table';
import { useInputsContext } from './context';
import InputsDocuments from './documents/documents';
import InputsBatchs from './batchs/batchs';

function InputsTabs() {
  const { t } = useTranslation();
  const { tabsTitles, state, refetchTable, refetchTableDocuments, collect } = useInputsContext();

  function renderChildren(key) {
    switch (key) {
      case 'reports':
        return <InputsTable />;
      case 'documents':
        return <InputsDocuments />;
      case 'batchs':
        return <InputsBatchs />;
      default:
    }
  }

  function handleChange(e) {
    switch (e) {
      case 'reports':
        return refetchTable();
      case 'documents':
        return refetchTableDocuments();
      case 'batchs':
        break;
      default:
    }
  }

  return (
    <>
      {state?.reportType ? (
        <Tabs
          defaultActiveKey="1"
          type="card"
          size="large"
          onChange={(e) => handleChange(e)}
          items={tabsTitles?.map((item) => {
            if (item.key === 'documents' && collect) {
              return;
            }
            return {
              label: item.label,
              key: item.key,
              children: renderChildren(item.key),
            };
          })}
        />
      ) : (
        <Row justify="center">{`${t('select')} ${t('reportType')}`}</Row>
      )}
    </>
  );
}

export default InputsTabs;
