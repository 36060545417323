import { Spin } from 'antd';
import Cookies from 'js-cookie';
import React, { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticationWrap } from './style';

const AuthLayout = (WraperContent) => {
  return function () {
    const { i18n } = useTranslation();

    useEffect(() => {
      const defaultLang = Cookies.get('defaultLang');
      i18n.changeLanguage(defaultLang || navigator.language);
    }, []);

    return (
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <AuthenticationWrap
          style={{
            backgroundImage: `url("${require('../../static/img/city.jpg')}")`,

            height: '100vh',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <div className="ninjadash-authentication-wrap">
            <WraperContent />
          </div>
        </AuthenticationWrap>
      </Suspense>
    );
  };
};

export default AuthLayout;
