import axios from 'axios';
import { AccessToken } from '../Storage';
import { Encryptor } from '../../utility/encryptor';

const api = axios.create({
  headers: {
    accept: '*/*',
    'Content-Type': 'application/json-patch+json',
  },
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const tokenEncrypted = sessionStorage.getItem([AccessToken]);
    const token = Encryptor.decrypted(tokenEncrypted);
    const headers = { ...config.headers };
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    return { ...config, headers };
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    return Promise.reject(error);
  },
);
export default api;
