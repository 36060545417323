import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Row, Col, Checkbox, Divider, Typography, Modal, Spin, Button, Tabs } from 'antd';
import { useToasts } from 'react-toast-notifications';
import { PageHeader } from '../../components/page-headers/page-headers';
import { getCookiesEncrypted } from '../../utility/localStorageControl';
import { ActiveContract } from '../../config/Storage';
import { CardToolbox } from '../style';
import { getRelat } from '../../services/dashboard';
import './style.css';
import { getAllPlants } from '../../services/plants';
import DoughnutChart from '../../components/charts/DoughnutChart';

const arrayCommonColors = [
  '#88d1ca',
  '#ded6c9',
  '#e68a2e',
  '#c90a00',
  '#452b34',
  '#00a0b0',
  '#6a4a3c',
  '#cc333f',
  '#eb6841',
  '#edc951',
  '#b7cbbf',
  '#8c886f',
  '#f9a799',
  '#f4bfad',
  '#f5dabd',
  '#f3d597',
  '#b6d89c',
  '#92ccb6',
  '#f87887',
  '#9e6b7c',
  '#7e949e',
  '#aec2ab',
  '#ebcea0',
  '#fc7765',
  '#ff335f',
];
const arrayCommonToUse = [];
function Report() {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { Title } = Typography;
  const [loadingTable, setLoadingTable] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [dataSummary, setDataSummary] = useState(null);
  const [dataGraph, setDataGraph] = useState(null);
  const [update, setUpdate] = useState(true);
  const [plants, setPlants] = useState([]);
  const [checkedPlants, setCheckedPlants] = useState([]);
  const [checkedAll, setCheckedAll] = useState(true);

  const [activeContract, setActiveContract] = useState({});

  useEffect(() => {
    const cookies = getCookiesEncrypted(ActiveContract);
    setActiveContract(cookies);
    getAllPlants(cookies.id).then((res) => {
      const group = [];
      const checks = [];
      res?.units?.map((unit) => {
        group.push({ label: unit.organization.name, value: unit.id });
        checks.push(unit.id);
        return unit;
      });
      setCheckedPlants([...checks]);
      setPlants([...group]);
    });
    handleRelat(cookies.id, cookies.contract_language, 0);
  }, []);

  async function handleRelat(contractId, contractLanguage, unit = 0) {
    if (contractId !== 0) {
      setLoadingTable(true);
      const data = {
        type: 'summary',
        contract_id: contractId,
        language: contractLanguage,
        unit,
      };
      getRelat(data)
        .then((res) => {
          setDataSummary(res.data);
          setDataGraph({ language: contractLanguage, data: res.dataGraph });
        })
        .catch(() => {
          setDataSummary([]);
          setDataGraph({ language: contractLanguage, data: [] });
          errorMessage(t('errorWhenEmissionReport'));
          setLoadingTable(false);
        })
        .finally(() => {
          setLoadingTable(false);
          setUpdate(!update);
        });
    }
  }
  function errorMessage(error) {
    return addToast(error, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  function renderTable() {
    return Array.isArray(dataSummary) && dataSummary.length ? (
      dataSummary?.map((scope) => (
        <>
          <Row key={scope.title}>
            <Col span={24}>
              <Title level={4}>{scope.title}</Title>
              {Array.isArray(scope.dataSet) &&
                scope.dataSet?.map((category, innerIndex) => (
                  <Table
                    key={`${scope.scope}-${innerIndex}`}
                    className={`ant-table-header-scope${scope.scope}`}
                    dataSource={category.dataSource}
                    columns={category.columns}
                    bordered
                    rowClassName={(record) =>
                      record.type === 'total'
                        ? `table-row-dark-scope${scope.scope}`
                        : record.type === 'resume'
                        ? `table-row-dark-resume${record.scope}`
                        : ''
                    }
                    showHeader={category.showHeader}
                    pagination={false}
                    width="80%"
                    loading={loadingTable}
                    style={{ marginBottom: 20 }}
                  />
                ))}
            </Col>
          </Row>
        </>
      ))
    ) : (
      <Row justify="center">{t('noSummaryFound')}</Row>
    );
  }

  function renderGraph() {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= 3; i++) {
      arrayCommonToUse.push(
        arrayCommonColors
          .map((value) => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value),
      );
    }
    const arrayTotalBasic = ['#a8c7fa', '#fff2cc', '#f8cbad'];
    const arrayTotalToUse = [];
    if (dataGraph !== null) {
      dataGraph?.data?.map((item) => arrayTotalToUse.push(arrayTotalBasic[item.scope - 1]));
      return Array.isArray(dataGraph.data) && dataGraph?.data?.length ? (
        dataGraph?.data?.map((item, innerIndex) => (
          <>
            <Row key={item.scope}>
              <Col span={24}>
                <Title level={4}>{item.title}</Title>
                <DoughnutChart
                  update={update}
                  key={`${item.scope}-${innerIndex}`}
                  style={{ margin: 10 }}
                  id={`${item.scope}-${innerIndex}`}
                  labels={item.labels}
                  clip={{ left: 5, top: false, right: -2, bottom: 0 }}
                  datasets={[
                    {
                      data: item.data,
                      backgroundColor: item.scope === 4 ? arrayTotalToUse : arrayCommonToUse[innerIndex],
                    },
                  ]}
                  option={{
                    cutoutPercentage: 70,
                    borderColor: 'white',
                    maintainAspectRatio: true,
                    responsive: true,

                    plugins: {
                      legend: {
                        display: true,
                        position: 'right',
                      },
                      animation: {
                        animateScale: true,
                        animateRotate: true,
                      },
                    },
                  }}
                />
              </Col>
            </Row>
          </>
        ))
      ) : (
        <Row justify="center">{t('noGraphFound')}</Row>
      );
    }
  }
  /*
  function renderOverview() {
    return Array.isArray(dataSummary) && dataSummary.length ? (
      dataSummary?.map((scope) => (
        <>
          <Row key={scope.title}>
            <Col span={24}>
              <Title level={4}>{scope.title}</Title>
              {Array.isArray(scope.dataSet) &&
                scope.dataSet?.map((category, innerIndex) => (
                  <Table
                    key={`${scope.scope}-${innerIndex}`}
                    className={`ant-table-header-scope${scope.scope}`}
                    dataSource={category.dataSource}
                    columns={category.columns}
                    bordered
                    rowClassName={(record) =>
                      record.type === 'total'
                        ? `table-row-dark-scope${scope.scope}`
                        : record.type === 'resume'
                        ? `table-row-dark-resume${record.scope}`
                        : ''
                    }
                    showHeader={category.showHeader}
                    pagination={false}
                    width="80%"
                    loading={loadingTable}
                    style={{ marginBottom: 20 }}
                  />
                ))}
            </Col>
          </Row>
        </>
      ))
    ) : (
      <Row justify="center">{t('noSummaryFound')}</Row>
    );
  }
  */
  return (
    <div style={{ padding: 20 }}>
      <CardToolbox>
        <PageHeader ghost title={t('emissionsSummary')} />
      </CardToolbox>
      <Modal
        open={openModal}
        title={t('downloadEmissions')}
        footer={null}
        closable={!loadingTable}
        onCancel={() => setOpenModal(false)}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>{!loadingTable ? null : <Spin />}</div>
      </Modal>
      <Row>
        <Col xxl={6} lg={8} sm={24} xs={24}>
          <Row>
            <Col style={{ width: '80%', paddingLeft: '28px', paddingRight: '28px' }} span={24}>
              <Title level={5}>{t('plantSelection')}</Title>

              <Checkbox
                onChange={(e) => {
                  setCheckedAll(e.target.checked);
                  const checks = [];
                  if (e.target.checked) {
                    plants?.map((item) => {
                      checks.push(item.value);
                      return item;
                    });
                  }

                  setCheckedPlants([...checks]);
                }}
                checked={checkedAll}
              >
                {t('allPlants')}
              </Checkbox>
              <Divider dashed />
              <Row>
                <Checkbox.Group
                  className="checkbox-plants"
                  name="plants"
                  options={plants}
                  value={checkedPlants}
                  onChange={(e) => {
                    if (e.length === plants.length) {
                      setCheckedAll(true);
                    } else {
                      setCheckedAll(false);
                    }
                    setCheckedPlants(e);
                  }}
                />
              </Row>
              <Divider dashed />
              <Button
                onClick={() => handleRelat(activeContract.id, activeContract.contract_language, checkedPlants)}
                className="btn-add_new"
                size="default"
                type="primary"
                key="1"
                style={{ marginBottom: 20 }}
              >
                {t('toUpdate')}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xxl={18} lg={16} sm={24} xs={24}>
          <Tabs
            defaultActiveKey="1"
            type="card"
            size="large"
            items={[
              {
                label: t('summary'),
                key: `summary`,
                children: renderTable(),
              },
              {
                label: t('graphic'),
                key: `graphic`,
                children: renderGraph(),
              },
              // {
              // label: t('overview'),
              //  key: `overview`,
              //  children: renderOverview(),
              // },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
}
export default Report;
