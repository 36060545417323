import { Encryptor } from './encryptor';

const getItem = (key) => {
  const data = sessionStorage.getItem(key) ? sessionStorage.getItem(key) : '';
  try {
    return JSON.parse(data);
  } catch (err) {
    return data;
  }
};

const getCookiesEncrypted = (key) => {
  const encrypted = getItem([key]);
  const decrypted = Encryptor.decrypted(encrypted);
  try {
    return JSON.parse(decrypted);
  } catch (err) {
    return decrypted;
  }
};

const setItem = (key, value) => {
  const stringify = typeof value !== 'string' ? JSON.stringify(value) : value;
  return sessionStorage.setItem(key, stringify);
};

const removeItem = (key) => {
  sessionStorage.removeItem(key);
};

export { getItem, getCookiesEncrypted, setItem, removeItem };
