/* eslint-disable import/no-cycle */
/* eslint-disable no-plusplus */
/* eslint-disable no-unsafe-optional-chaining */

import { logOut } from '../redux/authentication/actionCreator';

/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text.split(' ').slice(0, size).join(' ')}...`;
};

const idGenerator = (events, length = 1) => {
  const arrayData = [];
  events.map((data) => {
    return arrayData.push(parseInt(data.id, 10));
  });
  const number = (Math.max(...arrayData) + 1).toString();
  return number.length < length ? `${'0'.repeat(length - number.length)}${number}` : number;
};

function handleError(error, dispatch, navigate) {
  // console.log(error);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (!error?.response) error = { response: { data: false, status: false } };
  const { data, status } = error?.response;
  let message = '';
  if (data) {
    message = data.error.message;
  }

  switch (status) {
    case 301:
      // message = message ?? 'Verifique os dados enviados';
      break;
    case 400:
      message = message ?? 'Verifique os dados enviados';
      break;
    case 401:
      dispatch(logOut(() => navigate('/')));
      break;
    case 404:
      message = 'Nenhum registro encontrado!';
      break;
    case 409:
      message = message ?? 'Conflito na atualização do registro!';
      break;
    case 500:
      message = message ?? 'Erro no servidor';
      break;
    default:
      message = 'Erro desconhecido!';
      break;
  }

  if (message) return message;
}
function removeAllCookies() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
}

function normalizePhone(phone) {
  return parseInt(phone.replace(/[^0-9]/g, ''))?.toString();
}

function replaceKeycode(keycode) {
  return keycode.replaceAll('-', '/');
}

export { ellipsis, idGenerator, handleError, removeAllCookies, normalizePhone, replaceKeycode };
