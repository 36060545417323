/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import { Col, Row, Select, Steps } from 'antd';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StepsContainer } from './style';
import { useInputsContext } from './context';
import { Cards } from '../../components/cards/frame/cards-frame';
import { replaceKeycode } from '../../utility/utility';

// eslint-disable-next-line react/prop-types
function InputsSteps() {
  const {
    state,
    categories,
    subCategories,
    reportTypes,
    reportScopes,
    setState,
    setCategories,
    setSubCategories,
    setReportTypes,
    plants,
  } = useInputsContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Cards headless>
      <Row>
        <Steps
          items={[
            {
              title: t('unit'),
            },
            {
              title: t('scope'),
            },
            {
              title: t('category'),
            },
            {
              title: t('subCategory'),
            },
            {
              title: t('reportType'),
            },
          ]}
          direction="horizontal"
          current={-1}
        />
      </Row>
      <StepsContainer>
        <Row justify="space-between">
          <Col xxl={4} xl={4} md={4} xs={24} style={{ marginTop: 10 }}>
            <Select
              style={{
                width: '100%',
              }}
              onChange={(value) => {
                if (state?.reportType) {
                  setState({ ...state, unit: value });
                  navigate(`/inputs/${value}/${replaceKeycode(state?.reportType)}`);
                } else {
                  setState({ unit: value, scope: null, category: null, subCategory: null });
                  navigate(`/inputs/${value}`);
                }
              }}
              placeholder={`${t('select')} ${t('unit')}`}
              value={state?.unit}
            >
              {Array.isArray(plants) &&
                plants?.map((item) => <Select.Option value={item?.id}>{item?.organization?.name}</Select.Option>)}
            </Select>
          </Col>
          <Col xxl={4} xl={4} md={4} xs={24} style={{ marginTop: 10 }}>
            <Select
              style={{
                width: '100%',
              }}
              onChange={(value) => {
                setState({ unit: state?.unit, scope: value, category: null, subCategory: null });
                setCategories([]);
                setSubCategories([]);
                setReportTypes([]);

                navigate(`/inputs/${state?.unit}/${replaceKeycode(value)}`);
              }}
              placeholder={`${t('select')} ${t('scope')}`}
              value={state?.scope}
            >
              {Array.isArray(reportScopes) &&
                reportScopes?.map((item) => <Select.Option value={item?.keycode}>{item?.title}</Select.Option>)}
            </Select>
          </Col>
          <Col xxl={4} xl={4} md={4} xs={24} style={{ marginTop: 10 }}>
            <Select
              style={{
                width: '100%',
              }}
              onChange={(value) => {
                setState({ unit: state?.unit, scope: state.scope, category: value, subCategory: null });
                setSubCategories([]);
                setReportTypes([]);
                navigate(`/inputs/${state?.unit}/${replaceKeycode(value)}`);
              }}
              placeholder={`${t('select')} ${t('category')}`}
              value={state?.category}
            >
              {Array.isArray(categories) &&
                categories?.map((item) => <Select.Option value={item?.keycode}>{item?.title}</Select.Option>)}
            </Select>
          </Col>
          <Col xxl={4} xl={4} md={4} xs={24} style={{ marginTop: 10 }}>
            <Select
              style={{
                width: '100%',
              }}
              onChange={(value) => {
                setState({
                  unit: state?.unit,
                  scope: state?.scope,
                  category: state?.category,
                  subCategory: value,
                });
                setReportTypes([]);
                navigate(`/inputs/${state?.unit}/${replaceKeycode(value)}`);
              }}
              placeholder={`${t('select')} ${t('subCategory')}`}
              value={state?.subCategory}
              disabled={state?.subCategory === 'n/a'}
            >
              {Array.isArray(subCategories) &&
                subCategories?.map((item) => <Select.Option value={item?.keycode}>{item?.title}</Select.Option>)}
            </Select>
          </Col>
          <Col xxl={4} xl={4} md={4} xs={24} style={{ marginTop: 10 }}>
            <Select
              style={{
                width: '100%',
              }}
              onChange={(value) => {
                navigate(`/inputs/${state?.unit}/${replaceKeycode(value)}`);
              }}
              placeholder={`${t('select')} ${t('reportType')}`}
              value={state?.reportType}
            >
              {Array.isArray(reportTypes) &&
                reportTypes?.map((item) => <Select.Option value={item?.keycode}>{item?.title}</Select.Option>)}
            </Select>
          </Col>
        </Row>
      </StepsContainer>
    </Cards>
  );
}

export default InputsSteps;
