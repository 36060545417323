/* eslint-disable no-unused-vars */
/* eslint-disable no-eval */
/* eslint-disable react/prop-types */

import React from 'react';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import CurrencyInput from 'react-currency-input-field';

function NumberForm(props) {
  const { t } = useTranslation();
  const {
    label,
    name,
    style = { textAlign: 'right', width: '100%', color: '#0A0A0A' },
    xxl,
    xl,
    md,
    xs,
    span = 12,
    breakLine = false,
    breakBefore = false,
    disabled,
    readOnly,
    locale,
    decimal = 0,
    type,
    visible = true,
    mandatory,
    errorsNumbers,
    valuesNumber,
    setValuesNumber,
    setErrorsNumbers,
    min,
    max,
    placeholder = `${t('input')} ${label}`,
    affectedBy,
    affected,
    setAffected,
    willAffected,
    warningMessage,
    verifyGroup,
  } = props;
  function handleValueAffected(value) {
    if (willAffected) {
      const numbersWillAffected = [].slice.call(document.getElementsByClassName(willAffected));
      const total = numbersWillAffected
        .map((item) => {
          if (item.id === name) {
            return Number(value);
          }
          return Number(item.name);
        })
        .reduce((prev, next) => prev + next);
      const valuesVerify = { [willAffected]: total };
      if (eval(verifyGroup)) {
        setAffected({ [willAffected]: total });
      } else {
        setValuesNumber({ ...valuesNumber, [name]: { value: null, float: null } });
        setAffected({ [willAffected]: total - value });
        const message = verifyGroup.split('valuesVerify.');
        return warningMessage(t('notInputValue') + message[1]);
      }
    }
  }

  function renderValue() {
    if (affectedBy) {
      if (affected[name.split('.')[0]]) {
        return eval(
          `const ${willAffected} = ${
            affected[name.split('.')[0]]
          }; const value = ${affectedBy}; ${willAffected} ? value.toFixed(2) : ''`,
        );
      }
      if (valuesNumber[name]?.value) {
        return valuesNumber[name]?.value;
      }
    }
    return valuesNumber[name]?.value ?? '';
  }

  return (
    <>
      {breakBefore && <Col span={24} />}

      <Col xxl={xxl} xl={xl} md={md} xs={xs} span={visible ? span : 0}>
        <div style={{ margin: '3px 7px' }}>
          <div style={{ color: '#0A0A0A', fontWeight: 500 }}>
            {mandatory && (
              <span
                style={{
                  color: '#ff4d4f',
                  margin: '2px 2px 0px 0px',
                  display: 'inline-block',
                  fontSize: '15px',
                  fontFamily: 'SimSun, sans-serif',
                  lineHeight: 1,
                }}
              >
                *
              </span>
            )}
            {label}:
          </div>
          <CurrencyInput
            value={renderValue()}
            name={affectedBy ? null : valuesNumber[name]?.float}
            style={{ ...style, borderColor: errorsNumbers[name] ? '#FF0F0F' : null }}
            readOnly={readOnly}
            disabled={disabled}
            id={name}
            className={`numbers-input ant-input ant-input-sm ${mandatory ? 'fieldRequired' : ''} ${
              !affectedBy ? willAffected : ''
            }`}
            placeholder={placeholder}
            intlConfig={{ locale }}
            decimalsLimit={decimal}
            allowDecimals={type !== 'int'}
            allowNegativeValue={false}
            onBlur={() => {
              if (affectedBy) return;
              if (valuesNumber[name]?.float !== null) {
                if (min && valuesNumber[name]?.float < min) {
                  setValuesNumber({ ...valuesNumber, [name]: { value: min, float: min } });
                  return handleValueAffected(min);
                }
                if (max && valuesNumber[name]?.float > max) {
                  setValuesNumber({ ...valuesNumber, [name]: { value: max, float: max } });
                  return handleValueAffected(max);
                }
              }
              handleValueAffected(valuesNumber[name]?.float);
            }}
            onValueChange={(_, und, masked) => {
              if (affectedBy) return;
              setValuesNumber({ ...valuesNumber, [name]: { value: masked.value, float: masked.float } });
              if (mandatory && !masked.value) {
                setErrorsNumbers({ ...errorsNumbers, [name]: t('fieldRequired') });
              }

              if (mandatory && masked.value) {
                setErrorsNumbers({ ...errorsNumbers, [name]: '' });
              }
            }}
          />

          <div className="ant-form-item-explain-error">{errorsNumbers[name]}</div>
        </div>
      </Col>
      {breakLine && <Col span={24} />}
    </>
  );
}

export default NumberForm;
