import { UilAngleDown, UilSignout, UilUser, UilBuilding, UilMoon, UilSun } from '@iconscout/react-unicons';
import { Avatar, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Cookies from 'js-cookie';
import { InfoWraper, UserDropDwon } from './auth-info-style';
import { logOut } from '../redux/authentication/actionCreator';
import Heading from '../components/heading/heading';
import { Popover } from '../components/popup/popup';
import { getCookiesEncrypted } from '../utility/localStorageControl';
import { ActiveContract, Contracts, ImageSrc } from '../config/Storage';
import { changeLayoutMode } from '../redux/themeLayout/actionCreator';
import { Encryptor } from '../utility/encryptor';
import actionsAuth from '../redux/authentication/actions';

const { setLogo, setIsMaster } = actionsAuth;

const HeaderInfo = React.memo(() => {
  const { t } = useTranslation();
  const [config, setConfig] = useState('');
  const [letterUser, setLetterUser] = useState('');
  const [theme, setTheme] = useState(true);
  const [master, setMaster] = useState(false);
  const { info, refresh } = useSelector((state) => state.auth);
  useEffect(() => {
    const mode = Cookies.get('mode');
    dispatch(changeLayoutMode(mode === 'darkMode' ? 'darkMode' : 'lightMode'));
    const imageSrcEncrypt = sessionStorage.getItem([ImageSrc]);
    const imageSrc = imageSrcEncrypt ? Encryptor.decrypted(imageSrcEncrypt) : '';
    dispatch(setLogo(imageSrc));
    setTheme(mode !== 'darkMode');
    const cookies = getCookiesEncrypted(ActiveContract);
    setMaster(cookies?.ismaster);
    dispatch(setIsMaster(cookies?.ismaster));
    getInfos();
  }, [info]);
  function getInfos() {
    const cookies = getCookiesEncrypted(Contracts);
    const activeContract = getCookiesEncrypted(ActiveContract);

    const data = cookies || info;
    setConfig({ ...data, position: activeContract?.position });
    const user = data?.name?.split(' ');
    if (user?.length) {
      const letters = user
        .map((word) => {
          return word[0].toUpperCase();
        })
        .join('')
        .substring(0, 2);
      setLetterUser(letters);
    }
  }

  useEffect(() => {
    getInfos();
  }, [refresh]);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const SignOut = (e) => {
    e.preventDefault();
    dispatch(logOut(() => navigate('/')));
  };
  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <figcaption>
            <Heading as="h5">{config?.name}</Heading>
            <p>{config?.position}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to="/user-profile">
              <UilUser />
              {t('yourProfile')}
            </Link>
          </li>
          {master && (
            <>
              <li>
                <Link to="/company-profile">
                  <UilBuilding /> {t('companyData')}
                </Link>
              </li>
            </>
          )}
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <UilSignout /> {t('signOut')}
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      <Switch
        checkedChildren={<UilSun size={18} />}
        unCheckedChildren={<UilMoon size={18} />}
        checked={theme}
        onChange={(e) => {
          setTheme(!theme);
          dispatch(changeLayoutMode(e ? 'lightMode' : 'darkMode'));
        }}
      />
      <div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="ninjadash-nav-action-link">
            <Avatar style={{ backgroundColor: '#ccffcc', color: 'rgb(0, 140, 0)', fontWeight: 'bold' }}>
              {letterUser}
            </Avatar>
            <span className="ninjadash-nav-actions__author--name">{config?.name}</span>
            <UilAngleDown />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
});

export default HeaderInfo;
