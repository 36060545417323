const actions = {
  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERR: 'LOGIN_ERR',
  LOGIN_INFO: 'LOGIN_INFO',
  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERR: 'LOGOUT_ERR',
  REFRESH: 'REFRESH',
  LOGO: 'LOGO',
  ISMASTER: 'ISMASTER',

  loginBegin: () => {
    return {
      type: actions.LOGIN_BEGIN,
    };
  },

  setLogo: (data) => {
    return {
      type: actions.LOGO,
      data,
    };
  },

  setIsMaster: (data) => {
    return {
      type: actions.ISMASTER,
      data,
    };
  },

  loginSuccess: (data) => {
    return {
      type: actions.LOGIN_SUCCESS,
      data,
    };
  },

  loginInfo: (data) => {
    return {
      type: actions.LOGIN_INFO,
      data,
    };
  },

  loginErr: (err) => {
    return {
      type: actions.LOGIN_ERR,
      err,
    };
  },

  logoutBegin: () => {
    return {
      type: actions.LOGOUT_BEGIN,
    };
  },

  logoutSuccess: (data) => {
    return {
      type: actions.LOGOUT_SUCCESS,
      data,
    };
  },

  logoutErr: (err) => {
    return {
      type: actions.LOGOUT_ERR,
      err,
    };
  },

  setRefresh: (data) => {
    return {
      type: actions.REFRESH,
      data,
    };
  },
};

export default actions;
