/* eslint-disable no-eval */
/* eslint-disable no-new */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Form, Button, Spin, Modal } from 'antd';
import { useToasts } from 'react-toast-notifications';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { PageHeader } from '../../components/page-headers/page-headers';
import { AddModal, CardToolbox, Main, PageHeaderStyle } from '../style';
import { Cards } from '../../components/cards/frame/cards-frame';

import Heading from '../../components/heading/heading';
import InputsForm from '../inputs/relats/form';
import { handleError } from '../../utility/utility';
import { postCollect, putCollect } from '../../services/collect';

const { confirm } = Modal;

function Collect() {
  const { t } = useTranslation();

  const info = useSelector((state) => state.collect.info);
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [errorsNumbers, setErrorsNumbers] = useState({});
  const [valuesNumber, setValuesNumber] = useState({});
  const [affected, setAffected] = useState({});
  const [dataEdit, setDataEdit] = useState({});

  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (info?.form_config?.fields.length) {
      const newFields = info?.form_config?.fields?.map((item) => {
        if (item.type.includes('decimal')) {
          item.decimal = item.type.match(/\((.*)\)/).pop();
        }

        if (item.verify) {
          const name = item.verify.split(/[^A-Za-z]/);
          item.verifyName = name[0];
          item.verify = `valuesVerify.${item.verify}`;
        }
        if (item.name === 'email') {
          item.request = {
            url: `reports/collect-check/${info?.collect_config?.unit?.keycode}`,
            method: 'POST',
            data: {
              year: info?.collect_config?.contract?.year,
              user_language: info?.collect_config?.user_language,
              email: '',
            },
          };
        }
        return item;
      });

      populateFields(null, newFields);
    }
  }, [info]);

  useEffect(() => {
    let countModals = 0;
    let modals = {};
    Object.keys(valuesNumber).forEach((name) => {
      const nameSplit = name.split('.');
      if (nameSplit[0] === 'modals' && valuesNumber[name].float) {
        modals = { ...modals, [name]: valuesNumber[name] };

        countModals += 1;
      } else if (nameSplit[0] === 'modals' && !valuesNumber[name].float) {
        modals = { ...modals, [name]: null };
      }
    });
    const newFields = fields.map((el) => {
      if (el?.components) {
        el?.components.map((component) => {
          if (countModals === 6 && modals[component.name]?.float === undefined) {
            component.disabled = true;
          } else if (countModals < 6) {
            component.disabled = false;
          }
          return component;
        });
      }
      return el;
    });
    setFields([...newFields]);
  }, [valuesNumber]);

  function populateFields(data, infos, email) {
    form.resetFields();

    if (data?.id) {
      setDataEdit(data);
      let items = {};
      Object.keys(data).forEach((item) => {
        if (typeof data[item] === 'object') {
          items = { ...items, [item]: data[item]?.id };
        }

        if (Array.isArray(data[item])) {
          const values = data[item].reduce((result, value) => {
            const key = Object.keys(value)[0];
            result[`${item}.${key}`] = value[key];
            return result;
          }, {});
          items = { ...items, ...values };
        }
      });
      const optionsDependents = [];

      const elements = fields.map((el) => {
        if (el.dependents) {
          const elOptions = el?.options?.filter((value) => {
            return value.id == items[el.name];
          });
          optionsDependents.push({ name: el.dependents, options: elOptions[0]?.[el.dependents] ?? el.options });
        }
        return el;
      });
      const namesNumbers = [];

      const newFields = elements.map((el) => {
        if (el.required) {
          el.mandatory = true;
        } else {
          el.mandatory = false;
        }
        if (el.dependencies) {
          const element = optionsDependents?.find((item) => item.name === el.name);

          el.options = element?.options;
          if (el.required) {
            el.disabled = false;
          }
          if (el.type === 'group') {
            el.visible = false;
            const objController = elements.find((item) => item.name === el.dependencies);
            const groupControlled =
              objController.options.find((item) => item.value === data[el.dependencies])?.controlled_group ??
              objController.options.find((item) => item.value === data[el.dependencies])?.controlled_group;

            el.visible = el.name === groupControlled ? true : el.visible;
          }
        }
        if (el.type.includes('decimal') || el.type.includes('int')) {
          namesNumbers.push(el.name);
        }
        return el;
      });

      setFields([...newFields]);

      const values = { ...data, ...items };

      let valuesNumbers = {};
      Object.keys(values).forEach((item) => {
        if (typeof values[item] === 'number') {
          valuesNumbers = { ...valuesNumbers, [item]: { value: values[item], float: values[item] } };
        }
      });
      setValuesNumber({ ...valuesNumbers });

      form.setFieldsValue(values);
    } else if (infos) {
      const newFields =
        infos?.map((item) => {
          if (item.dependencies) {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
          if (item.required) {
            item.mandatory = true;
          } else {
            item.mandatory = false;
          }

          return item;
        }) ?? [];
      form.setFieldsValue({});
      setDataEdit({});
      setFields([...newFields]);
    } else {
      handleClear();
      form.setFieldValue('email', email);
      form.validateFields(['email']);
    }
  }

  const handleClear = () => {
    setDataEdit({});
    let valuesNumbers = {};
    Object.keys(valuesNumber).forEach((item) => {
      if (typeof valuesNumber[item] === 'number') {
        valuesNumbers = { ...valuesNumbers, [item]: { value: '', float: '' } };
      }
    });
    setValuesNumber({ ...valuesNumbers });
    setErrorsNumbers({});
    form.resetFields();
    setAffected({});
  };
  function handleErrorNumber() {
    const numbersInput = [].slice.call(document.getElementsByClassName('numbers-input'));
    let numbers = null;
    let errors = errorsNumbers;
    let group = {};
    let total = {};

    numbersInput.map((item) => {
      const value = item.id.split('.');
      const required = !!item.className.includes('fieldRequired');
      if (value[1]) {
        if (Number(item.name)) {
          const valueGroup = group[value[0]] ?? [];
          valueGroup.push({ [value[1]]: Number(item.name) });
          group = { ...group, [value[0]]: valueGroup };
          const valueItem = total[value[0]] ?? 0;
          total = { ...total, [value[0]]: valueItem + Number(item.name) };
        } else if (required) {
          errors = { ...errors, [value[1]]: t('fieldRequired') };
        }
        numbers = { ...numbers, ...group };
        return numbers;
      }
      if (Number(item.name)) {
        total = { ...total, [value[0]]: Number(item.name) };

        numbers = { ...numbers, [value[0]]: Number(item.name) };
      } else if (required) {
        errors = { ...errors, [value[0]]: t('fieldRequired') };
      }
      return numbers;
    });
    let countErrorNumbers = 0;
    Object.keys(errors).forEach((item) => {
      if (errors[item]) {
        countErrorNumbers += 1;
      }
    });
    if (countErrorNumbers) {
      setErrorsNumbers({ ...errors });

      return { verify: false, numbers, total };
    }
    return { verify: true, numbers, total };
  }

  const handleSubmit = (values) => {
    const { numbers, verify, total } = handleErrorNumber();
    if (!verify) return;
    const items = { ...values, ...numbers };
    let valuesVerify = {};
    const errorsVerify = [];
    fields.map((item) => {
      if (item.visible && item.verify) {
        Object.keys(items).forEach((key) => {
          if (key === item.verifyName) {
            valuesVerify = { ...valuesVerify, [item.verifyName]: total[item.verifyName] };
          }
        });
        if (item.verify && !eval(item.verify)) {
          errorsVerify.push(item.verifyName);
        }
      }

      return item;
    });

    if (errorsVerify.length) {
      return errorMessage(
        handleError({
          response: {
            status: 301,
            data: { error: { message: `${t('errorInFields')}${errorsVerify.toString()}${t('checkAndTryAgain')}` } },
          },
        }),
      );
    }

    confirm({
      title: t('checkData'),
      icon: <ExclamationCircleFilled />,
      content: t('reallyConfirm'),
      cancelText: t('cancel'),
      onOk() {
        if (dataEdit?.id) {
          const record = {
            keycode: info?.form_baseInfos?.keycode,
            id: dataEdit?.id,
            data: {
              ...info?.form_baseInfos,
              ...items,
            },
          };

          editCollect.mutate(record);
        } else {
          const record = {
            keycode: info?.form_baseInfos?.keycode,
            data: {
              ...info?.form_baseInfos,
              ...items,
            },
          };

          createCollect.mutate(record);
        }
      },
    });
  };

  function warningMessage(warning) {
    return addToast(warning, {
      appearance: 'warning',
      autoDismiss: true,
    });
  }

  function errorMessage(error) {
    return addToast(error, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  function successMessage(success) {
    return addToast(success, {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  const createCollect = useMutation(postCollect, {
    onError: (error) => {
      if (error) {
        return errorMessage(handleError(error, dispatch, navigate));
      }
    },
    onSuccess: (res) => {
      setDataEdit(res);
      navigate('/collect/confirmation', {
        state: {
          name: form.getFieldValue('name'),
        },
      });
      successMessage(`${t('report')} ${t('createSuccessfully')}`);
    },
  });

  const editCollect = useMutation(putCollect, {
    onError: (error) => {
      if (error) {
        return errorMessage(handleError(error, dispatch, navigate));
      }
    },
    onSuccess: () => {
      navigate('/collect/confirmation', {
        state: {
          name: form.getFieldValue('name'),
        },
      });
      successMessage(`${t('report')} ${t('updatedSuccessfully')}`);
    },
  });

  return (
    <>
      <Main>
        {info === null ? (
          <div className="spin">
            <Spin />
          </div>
        ) : info?.collect_config ? (
          <>
            <CardToolbox>
              <PageHeaderStyle>
                <PageHeader
                  ghost
                  title={t('itineraryCollection')}
                  subTitle={`${t('yourWorkUnit')} ${info?.collect_config?.unit?.name} ${
                    info?.collect_config?.unit?.local
                  }`}
                />
              </PageHeaderStyle>
            </CardToolbox>
            <Cards headless>
              <AddModal>
                <div className="project-modal ">
                  <Form
                    style={{ width: '100%' }}
                    form={form}
                    onFinish={handleSubmit}
                    onFinishFailed={() => {
                      handleErrorNumber();
                    }}
                    size="small"
                  >
                    <Heading className="form-title" as="h4">
                      {t('itineraryRegister')}
                    </Heading>
                    <Row>
                      <InputsForm
                        form={form}
                        locale={info?.collect_config?.user_language}
                        fields={fields}
                        setFields={setFields}
                        errorsNumbers={errorsNumbers}
                        valuesNumber={valuesNumber}
                        setValuesNumber={setValuesNumber}
                        setErrorsNumbers={setErrorsNumbers}
                        affected={affected}
                        setAffected={setAffected}
                        warningMessage={warningMessage}
                        populateFields={populateFields}
                      />
                    </Row>
                    <Row justify="end">
                      <div className="add-input-bottom text-right">
                        <Button htmlType="submit" type="primary">
                          {t('submit')}
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </div>
              </AddModal>
            </Cards>
          </>
        ) : (
          <div
            style={{
              height: '70vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#ff9900',
            }}
          >
            {t('invalidAccessKey')}
          </div>
        )}
      </Main>
    </>
  );
}

export default Collect;
