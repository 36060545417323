/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Radio, Col, Form } from 'antd';
import { useTranslation } from 'react-i18next';

function RadioForm(props) {
  const { t } = useTranslation();
  const {
    label,
    name,
    xxl,
    xl,
    md,
    xs,
    span = 12,
    breakLine = false,
    breakBefore = false,
    onChange,
    mandatory,
    visible = true,
    options,
    size = 'middle',
    defaultValue = false,
    form,
  } = props;

  useEffect(() => {
    if (!form.getFieldValue(name)) {
      form.setFieldValue(name, defaultValue);
    }
  }, []);
  return (
    <>
      {breakBefore && <Col span={24} />}

      <Col xxl={xxl} xl={xl} md={md} xs={xs} span={visible ? span : 0}>
        <Form.Item label={label} name={name} rules={[{ message: t('fieldRequired'), required: !!mandatory }]}>
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            name={name}
            options={options}
            defaultValue={defaultValue}
            size={size}
            id={name}
            onChange={(e) => {
              const option = options.find((el) => el.value === e.target.value);
              onChange(option);
            }}
          />
        </Form.Item>
      </Col>
      {breakLine && <Col span={24} />}
    </>
  );
}

export default RadioForm;
