/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UilUsersAlt, UilFileDownloadAlt, UilChartBar, UilMap, UilAngleDown } from '@iconscout/react-unicons';
import { useToasts } from 'react-toast-notifications';
import { Modal, Spin } from 'antd';
import Flags from 'country-flag-icons/react/3x2';
import { TopMenuStyle } from './Style';
import { getCookiesEncrypted } from '../utility/localStorageControl';
import { Contracts, ActiveContract } from '../config/Storage';
import { Encryptor } from '../utility/encryptor';
import { getRelat } from '../services/dashboard';
import { Dropdown } from '../components/dropdown/dropdown';

function TopMenu() {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [activeContract, setActiveContract] = useState(null);

  const config = useSelector((state) => state.auth.info);
  const [infos, setInfos] = useState({});
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const cookies = getCookiesEncrypted(Contracts);
    const data = cookies || config;
    setInfos(data);
    loadActiveContract(data);
  }, [config]);

  function errorMessage(error) {
    return addToast(error, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  useLayoutEffect(() => {
    const active = document.querySelector('.ninjadash-top-menu a.active');
    const activeDefault = () => {
      const megaMenu = active.closest('.megaMenu-wrapper');
      const hasSubMenuLeft = active.closest('.has-subMenu-left');
      if (!megaMenu) {
        active.closest('ul').previousSibling.classList.add('active');
        if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
      } else {
        active.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
      }
    };
    window.addEventListener('load', active && activeDefault);
    return () => window.removeEventListener('load', activeDefault);
  }, []);

  function loadActiveContract(data) {
    const cookies = getCookiesEncrypted(ActiveContract);
    if (cookies) {
      setActiveContract(cookies);
    } else {
      data?.company?.contracts?.map((item) => {
        setActiveContract(item);
        sessionStorage.setItem([ActiveContract], Encryptor.encrypted(JSON.stringify(item)));
        return item;
      });
    }
  }

  function handleChangeYear(item) {
    sessionStorage.setItem([ActiveContract], Encryptor.encrypted(JSON.stringify(item)));
    window.location.reload();
  }

  async function handleRelat() {
    setLoadingDownload(true);
    const theFile = `${process.env.REACT_APP_API_ENDPOINT}/uploads/report_demo-02.pdf`;
    const link = document.createElement('a');
    link.href = theFile;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setLoadingDownload(false);
  }

  const items = activeContract?.report_languages?.map((item) => {
    const code = item?.language?.slice(item.language.length - 2);
    const Flag = Flags[code];
    return (
      <Link onClick={() => handleRelat()} to="#">
        <span>
          <Flag style={{ width: 30, heigth: 30 }} />
          {item?.language}
        </span>
      </Link>
    );
  });
  return (
    <>
      <Modal
        open={openModal}
        title={t('downloadEmissions')}
        footer={null}
        closable={!loadingDownload}
        onCancel={() => setOpenModal(false)}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {!loadingDownload ? (
            <div>
              <Dropdown placement="bottomRight" content={items} trigger="click">
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                  {t('selectLanguage')} <UilAngleDown />
                </div>
              </Dropdown>
            </div>
          ) : (
            <Spin />
          )}
        </div>
      </Modal>
      <TopMenuStyle>
        <div className="ninjadash-top-menu">
          <ul>
            <li>
              <Link to="/dashboard">{t('dashboard')}</Link>
            </li>
            <li className="has-subMenu">
              <a>
                {t('year')}
                <span style={{ marginLeft: 5, color: '#8231D3', fontWeight: 'bold' }}>{activeContract?.year}</span>
              </a>
              <ul className="subMenu">
                {infos?.company?.contracts?.map((item, i) => (
                  <li key={i}>
                    <a onClick={() => handleChangeYear(item)}>{item.year}</a>
                  </li>
                ))}
              </ul>
            </li>
            {activeContract?.ismaster && (
              <>
                <li className="has-subMenu">
                  <a to="#" className="parent">
                    {t('reports')}
                  </a>
                  <ul className="subMenu">
                    <li>
                      <Link to="/report">
                        <UilChartBar />
                        {t('emissionsSummary')}
                      </Link>
                    </li>
                    {activeContract.ismaster && (
                      <li>
                        <div className="ninjadash-nav-actions__item ninjadash-nav-actions__language">
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenModal(true);
                            }}
                          >
                            <UilFileDownloadAlt />
                            {t('emissionGEE')}
                          </Link>
                        </div>
                      </li>
                    )}
                  </ul>
                </li>
                <li className="has-subMenu">
                  <Link className="parent">{t('manage')}</Link>
                  <ul className="subMenu">
                    <li>
                      <Link to="/users">
                        <UilUsersAlt />
                        {t('users')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/plants">
                        <UilMap />
                        {t('units')}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            )}
          </ul>
        </div>
      </TopMenuStyle>
    </>
  );
}

export default TopMenu;
