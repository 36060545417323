const actions = {
  INPUTS_READ_BEGIN: 'INPUTS_READ_BEGIN',
  INPUTS_READ_SUCCESS: 'INPUTS_READ_SUCCESS',
  INPUTS_READ_ERR: 'INPUTS_READ_ERR',

  readBegin: () => {
    return {
      type: actions.INPUTS_READ_BEGIN,
    };
  },

  readSuccess: (data) => {
    return {
      type: actions.INPUTS_READ_SUCCESS,
      data,
    };
  },
  readErr: (err) => {
    return {
      type: actions.INPUTS_READ_ERR,
      err,
    };
  },
};

export default actions;
