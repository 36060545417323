/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import { Row, Col, Form, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AddModal } from '../../style';
import { Button } from '../../../components/buttons/buttons';
import { Modal } from '../../../components/modals/antd-modals';
import UploadDragger from '../../../components/upload/Upload';
import { postDocument, putDocument } from '../../../services/inputs';
import { useInputsContext } from '../context';
import { handleError } from '../../../utility/utility';

function ModalDocuments({
  listRelats,
  listBatchs,
  visible,
  form,
  setFileList,
  fileList,
  setRemovedFiles,
  removedFiles,
  checkedRelats,
  checkedBatchs,
  setCheckedBatchs,
  setCheckedRelats,
  dataEdit,
  onCancel,
  refetch,
  title = '',
  tabDocument,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { errorMessage, successMessage, state, activeContract, contracts } = useInputsContext();

  function changeRelats(checkedValues) {
    setCheckedRelats(checkedValues);
  }

  const createDocument = useMutation(postDocument, {
    onError: (error) => {
      if (error) {
        return errorMessage(handleError(error, dispatch, navigate));
      }
    },
    onSuccess: (res) => {
      if (res.data) {
        onCancel();
        refetch();
        successMessage(`${t('documents')} ${t('createSuccessfully')}`);
      } else {
        return errorMessage(handleError(res, dispatch, navigate));
      }
    },
  });

  const editDocument = useMutation(putDocument, {
    onError: (error) => {
      if (error) {
        return errorMessage(handleError(error, dispatch, navigate));
      }
    },
    onSuccess: (res) => {
      if (res?.data) {
        onCancel();
        refetch();
        successMessage(`${t('documents')} ${t('updatedSuccessfully')}`);
      } else {
        return errorMessage(handleError(res, dispatch, navigate));
      }
    },
  });

  const handleSubmit = async () => {
    if (!fileList?.length) {
      return errorMessage(t('sendFile'));
    }
    if (tabDocument) {
      if (!checkedRelats?.length && !checkedBatchs?.length) {
        return errorMessage(t('reportOrBatch'));
      }
      if (dataEdit?.id) {
        const record = {
          keycode: state?.reportType,
          year: activeContract?.year,
          contract: activeContract?.id,
          company: contracts?.company?.id,
          unit: state?.unit,
          b_recs: null,
          updater_user_profile: activeContract?.userprofile_id,
        };
        record[state?.reportType.substring(18)] = checkedRelats;
        const files = new FormData();
        const filesExist = [];
        fileList?.forEach((file) => {
          if (!file.originFileObj) {
            filesExist.push(file.uid);
          } else {
            files.append('files', file.originFileObj, file.name);
          }
        });
        editDocument.mutate({ id: dataEdit?.id, record, files, filesExist, removedFiles });
      } else {
        const record = {
          keycode: state?.reportType,
          year: activeContract?.year,
          contract: activeContract?.id,
          company: contracts?.company?.id,
          unit: state?.unit,
          b_recs: null,
          updater_user_profile: activeContract?.userprofile_id,
        };
        const ids = checkedRelats ?? [dataEdit.id];
        record[state?.reportType.substring(18)] = ids;
        const files = new FormData();
        fileList?.forEach((file) => {
          files.append('files', file.originFileObj, file.name);
        });
        createDocument.mutate({ record, files });
      }
    } else {
      const record = {
        keycode: state?.reportType,
        year: activeContract?.year,
        contract: activeContract?.id,
        company: contracts?.company?.id,
        unit: state?.unit,
        b_recs: null,
        updater_user_profile: activeContract?.userprofile_id,
      };
      record[state?.reportType.substring(18)] = [dataEdit.id];

      const files = new FormData();
      fileList?.forEach((file) => {
        files.append('files', file.originFileObj, file.name);
      });
      createDocument.mutate({ record, files });
    }
  };

  return (
    <>
      <Modal
        width="800px"
        type="primary"
        title={`${t('new')} ${t('document')} ${title}`}
        visible={visible}
        footer={null}
        onCancel={onCancel}
        maskClosable={false}
      >
        <AddModal>
          <div className="project-modal">
            <Form style={{ width: '100%' }} form={form} onFinish={handleSubmit}>
              <Row>
                <Col span={24}>
                  <Form.Item label={`${t('select')} ${t('file')}`} name="doc_file">
                    <UploadDragger
                      setFileList={setFileList}
                      fileList={fileList}
                      setRemovedFiles={setRemovedFiles}
                      removedFiles={removedFiles}
                      accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,on/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip"
                    />
                  </Form.Item>
                </Col>
                {listRelats?.length ? (
                  <Col xxl={12} xl={12} md={24} xs={24}>
                    <Form.Item label={t('relats')} name="relats">
                      <Checkbox.Group
                        name="relats"
                        options={listRelats}
                        defaultValue={checkedRelats}
                        onChange={changeRelats}
                      />
                    </Form.Item>
                  </Col>
                ) : null}

                {listBatchs?.length ? (
                  <Col xxl={12} xl={12} md={24} xs={24}>
                    <Form.Item label={t('batchs')} name="batchs">
                      <Checkbox.Group name="batchs" options={checkedBatchs} onChange={setCheckedBatchs} />
                    </Form.Item>
                  </Col>
                ) : null}
              </Row>
              <Row justify="end">
                <div className="text-right" style={{ marginRight: 10 }}>
                  <Button type="danger" outlined onClick={() => onCancel()}>
                    {t('cancel')}
                  </Button>
                </div>
                <div className="add-input-bottom text-right">
                  <Button htmlType="submit" type="primary">
                    {t('submit')}
                  </Button>
                </div>
              </Row>
            </Form>
          </div>
        </AddModal>
      </Modal>
    </>
  );
}

export default ModalDocuments;
