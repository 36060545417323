import xlsx from 'json-as-xlsx';
import api from '../../config/api/api';

const downloadFile = async (file) => {
  const response = await api.get(`${process.env.REACT_APP_API_ENDPOINT}${file.url}`, { responseType: 'blob' });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', file.name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getColumnsForm = async (data) => {
  const response = await api.post('/report-types/getconfig', data);
  return response.data;
};
export const getDataTable = async (data) => {
  const response = await api.post(`/reports/get`, data);
  return response.data;
};

export const postInput = async (data) => {
  const response = await api.post(`/reports/create`, data);
  return { ...response.data };
};

export const getDocuments = async (data) => {
  const response = await api.post(`/reported-documents/getdata`, data);
  return response.data;
};

export const putInput = async (data) => {
  const response = await api.post(`/reports/update`, data);
  return { ...response.data };
};

export const deleteInput = async (data) => {
  const response = await api.post(`/reports/delete`, data);
  return { ...response.data };
};

export const getBatchs = async (data) => {
  const response = await api.post(`/reported-batchs/getdata`, data);
  return response.data;
};

export const postBatchs = async (data) => {
  const response = await api.post(`reported-batchs/createsingle`, data);
  return { ...response.data };
};

export const deleteBatch = async (data) => {
  const response = await api.post('/reported-batchs/deletesingle', data);
  return { ...response.data };
};

export const putDocument = async (data) => {
  try {
    const { record, files, filesExist, id, removedFiles } = data;
    const upload = Object.fromEntries(files).files && (await api.post('/upload', files));
    const newDocFiles = upload ? upload.data.map((item) => item.id) : [];
    record.doc_file = [...newDocFiles, ...filesExist];
    const response = await api.put(`/reported-documents/${id}`, { data: record });
    removedFiles.map((value) => {
      deleteFile(value);
      return value;
    });
    return { ...response.data };
  } catch (err) {
    return err;
  }
};

const deleteFile = (id) => {
  api.delete(`/upload/files/${id}`);
};

export const postDocument = async (data) => {
  try {
    const { record, files } = data;
    const upload = await postUpload(files);
    if (upload) {
      record.doc_file = upload.data.map((item) => item.id);
      record.id_contract = record.contract;
      record.id_unit = record.unit;
      const response = await api.post('/reported-documents', { data: record });
      return { ...response.data };
    }
  } catch (err) {
    return err;
  }
};

const postUpload = async (files) => {
  const upload = await api.post('/upload', files);
  return upload;
};

export const deleteDocument = async (data) => {
  const response = await api.post('/reported-documents/deletedoc', data);
  return { ...response.data };
};

export const downloadUpload = async (data) => {
  try {
    await Promise.all(data.map(downloadFile));
    return true;
  } catch (error) {
    return false;
  }
};

export const getUpload = async (url) => {
  const response = await api.post(url, {}, { responseType: 'blob' });
  return { ...response.data };
};

export const getXlsxEmissions = async (filename, infoEmissions) => {
  const settings = {
    fileName: filename,
    writeOptions: {
      type: 'base64',
      bookType: 'xlsx',
    },
  };
  try {
    const { data } = await api.post('/reported-emission-exports/getdata', infoEmissions);
    xlsx([data], settings);
  } catch (error) {
    // console.log(error);
  }
};

export const getXlsxForms = async (filename, infoEmissions) => {
  const settings = {
    fileName: filename,
    writeOptions: {
      type: 'base64',
      bookType: 'xlsx',
    },
  };
  try {
    const { data } = await api.post('/reported-emission-exports/getdata', infoEmissions);
    xlsx([data], settings);
  } catch (error) {
    // console.log(error);
  }
};
