/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import { Col, Row } from 'antd';
import React from 'react';
import {
  SelectForm,
  CheckForm,
  RadioForm,
  InputForm,
  TextAreaForm,
  NumberForm,
  SelectMultipleForm,
  AeroForm,
} from '../../../components/form';

const InputsForm = ({
  locale,
  form,
  fields,
  setFields,
  errorsNumbers,
  valuesNumber,
  setValuesNumber,
  setErrorsNumbers,
  affected,
  setAffected,
  warningMessage,
  populateFields = () => {},
}) => {
  function handleChangeOptions(option, dependent) {
    if (dependent !== null) {
      if (dependent?.startsWith('group')) {
        // Apenas para GROUP afeta ESCONDER/EXIBIR os Dependentes
        const groupDependents = dependent.split(',').map((el) => el.trim());

        fields.map((item, i) => {
          groupDependents.map((value) => {
            if (item?.name?.includes(value)) {
              fields[i].visible = false;
              const newComponents = fields[i].components?.map((component) => {
                component.mandatory = false;
                setErrorsNumbers({});
                return component;
              });

              fields[i].component = newComponents ? [...newComponents] : fields[i].component;
            }
            return value;
          });
          if (item?.name === option?.controlled_group) {
            fields[i].visible = true;
            const newComponents = item.components?.map((component) => {
              if (component.required) {
                component.mandatory = true;
              }
              return component;
            });
            fields[i].component = newComponents ? [...newComponents] : fields[i].component;
          }

          return item;
        });
      } else {
        // Não é GROUP (Dependência Afeta OPTIONS do  SELECT do dependente)
        const dependentIndex = fields.findIndex((el) => el.name === dependent);

        if (dependentIndex !== -1) {
          const field = fields[dependentIndex];
          field.options = option[dependent];
          field.disabled = false;
          if (field.required) {
            field.mandatory = true;
          }
          form.setFieldValue(dependent, null);
        }
      }
    }
    setFields([...fields]);
  }

  function handleType(type) {
    // Alterado para definit també DECIMAL
    if (type.includes('decimal')) {
      return { type: 'number', decimal: parseInt(type.substring(8).replace(')', '')) };
    }
    if (type.includes('int')) {
      return { type: 'number', decimal: 0 };
    }
    return { type, decimal: 0 };
  }
  const inputType = (params) => {
    const { type, decimal } = handleType(params.type);
    switch (type) {
      case 'text':
        return (
          <InputForm
            name={params.name}
            label={params.label}
            span={params.span}
            data={params.options}
            required={params.required}
            mandatory={params.mandatory}
            dependencies={params.dependencies}
            dependents={params.dependents}
            breakLine={params.break}
            breakBefore={params.breakBefore}
            visible={params.visible}
            disabled={params.disabled}
            readOnly={params.readOnly}
            placeholder={params.placeholder}
            onChange={() => setFields([...fields])}
            populateFields={populateFields}
            request={params.request}
          />
        );
      case 'select':
        return (
          <SelectForm
            name={params.name}
            label={params.label}
            value="id"
            display="title"
            span={params.span}
            data={params.options}
            required={params.required}
            mandatory={params.mandatory}
            dependencies={params.dependencies}
            dependents={params.dependents}
            onChange={(option) => handleChangeOptions(option, params.dependents)}
            disabled={params.disabled}
            readOnly={params.readOnly}
            breakLine={params.break}
            breakBefore={params.breakBefore}
            visible={params.visible}
            placeholder={params.placeholder}
          />
        );

      case 'select-multiple':
        return (
          <AeroForm
            name={params.name}
            label={params.label}
            value="id"
            display="title"
            span={params.span}
            data={params.options}
            required={params.required}
            mandatory={params.mandatory}
            dependencies={params.dependencies}
            dependents={params.dependents}
            disabled={params.disabled}
            readOnly={params.readOnly}
            breakLine={params.break}
            breakBefore={params.breakBefore}
            visible={params.visible}
            placeholder={params.placeholder}
            defaultValue={form.getFieldValue(params.name)}
            form={form}
          />
        );
      case 'checkbox':
        return (
          <CheckForm
            name={params.name}
            label={params.label}
            value="id"
            display="title"
            span={params.span}
            options={params.options}
            required={params.required}
            mandatory={params.mandatory}
            dependencies={params.dependencies}
            dependents={params.dependents}
            onChange={(option) => handleChangeOptions(option, params.dependents)}
            disabled={params.disabled}
            breakLine={params.break}
            breakBefore={params.breakBefore}
            visible={params.visible}
            placeholder={params.placeholder}
            initialValue={false}
          />
        );
      case 'radio':
        return (
          <RadioForm
            name={params.name}
            label={params.label}
            span={params.span}
            options={params.options}
            required={params.required}
            mandatory={params.mandatory}
            dependencies={params.dependencies}
            dependents={params.dependents}
            onChange={(option) => handleChangeOptions(option, params.dependents)}
            disabled={params.disabled}
            breakLine={params.break}
            breakBefore={params.breakBefore}
            visible={params.visible}
            placeholder={params.placeholder}
            defaultValue={form.getFieldValue(params.name)}
            form={form}
          />
        );
      case 'textarea':
        return (
          <TextAreaForm
            name={params.name}
            label={params.label}
            span={params.span}
            data={params.options}
            required={params.required}
            disabled={params.disabled}
            readOnly={params.readOnly}
            mandatory={params.mandatory}
            dependencies={params.dependencies}
            dependents={params.dependents}
            breakLine={params.break}
            breakBefore={params.breakBefore}
            visible={params.visible}
            placeholder={params.placeholder}
            onChange={() => setFields([...fields])}
          />
        );
      case 'number':
        return (
          <NumberForm
            name={params.name}
            label={params.label}
            span={params.span}
            data={params.options}
            required={params.required}
            mandatory={params.mandatory}
            dependencies={params.dependencies}
            dependents={params.dependents}
            locale={locale}
            form={form}
            defaultValue={form.getFieldValue(params.name)}
            decimal={decimal}
            type={params.type}
            breakLine={params.break}
            breakBefore={params.breakBefore}
            visible={params.visible}
            readOnly={params.readOnly}
            errorsNumbers={errorsNumbers}
            valuesNumber={valuesNumber}
            setValuesNumber={setValuesNumber}
            setErrorsNumbers={setErrorsNumbers}
            min={params.min}
            max={params.max}
            placeholder={params.placeholder}
            fields={fields}
            change={params.change}
            affectedBy={params.affectedBy}
            affected={affected}
            setAffected={setAffected}
            willAffected={params.willAffected}
            warningMessage={warningMessage}
            verifyGroup={params.verifyGroup}
            disabled={params.disabled}
          />
        );
      default:
        return (
          <InputForm
            name={params.name}
            label={params.label}
            value="id"
            display="title"
            span={params.span}
            data={params.options}
            required={params.required}
            disabled={params.disabled}
            readOnly={params.readOnly}
            mandatory={params.mandatory}
            dependencies={params.dependencies}
            dependents={params.dependents}
            breakLine={params.break}
            breakBefore={params.breakBefore}
            visible={params.visible}
            placeholder={params.placeholder}
            onChange={() => setFields([...fields])}
            populateFields={populateFields}
            request={params.request}
          />
        );
    }
  };

  const inputs = fields.flatMap((field, i) => {
    if (field?.type !== 'group') {
      return inputType(field, i);
    }
    // Tratamento para GROUP cria DIV que contém os campos (components)
    if (Array.isArray(field.components)) {
      const groupInputs = field.components.map((component, index) => {
        component.verifyGroup = field?.verify;
        return inputType(component, index);
      });
      if (groupInputs.length) {
        const span = field.span ? 100 / (24 / field.span) : 100;
        return (
          <Row
            key={field.name}
            label={field.label}
            name={field.name}
            style={{
              display: !field.visible ? 'none' : 'flex',
              flexFlow: 'row wrap',
            }}
            span={field.span}
          >
            <Col span={field.span} style={{ display: 'flex', flexFlow: 'row wrap', width: '100%' }}>
              <span span={24} style={{ fontWeight: 500, width: '100%' }}>
                {field.label}
              </span>
              {groupInputs}
            </Col>
          </Row>
        );
      }
    }
    return null;
  });
  return inputs;
};

export default InputsForm;
