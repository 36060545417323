import actions from './actions';

const initialState = {
  info: null,
  confirm: '',
};

const { INFO, CONFIRM } = actions;

const CollectReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case INFO:
      return {
        ...state,
        info: data,
      };
    case CONFIRM:
      return {
        ...state,
        confirm: data,
      };

    default:
      return state;
  }
};

export default CollectReducer;
