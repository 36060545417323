import axios from 'axios';

const api = axios.create({
  headers: {
    accept: '*/*',
    'Content-Type': 'application/json-patch+json',
  },
  baseURL: process.env.REACT_APP_BASE_URL,
});

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    return Promise.reject(error);
  },
);
export default api;
