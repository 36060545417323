/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import withCollect from '../layout/withCollect';

import Collect from '../pages/collect';
import ConfirmationCollect from '../pages/confirmationCollect';

const CollectRoute = React.memo(() => {
  return (
    <Routes>
      <Route index path="*" element={<Collect />} />
      <Route index path="/confirmation" element={<ConfirmationCollect />} />
    </Routes>
  );
});

export default withCollect(CollectRoute);
